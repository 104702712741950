import actions from './actions'

const initialState = {
  appData: [],
  service: null,
  devMode: false,
  logs: {
    current_page: 1,
    data: [],
    last_page: 1,
    links: [],
    per_page: 10,
    to: null,
    total: 0,
  },
  report: null,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CHANGE_SERVICE:
      return { ...state, service: action.payload }

    default:
      return state
  }
}
