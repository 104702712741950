import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import apiClient from 'services/axios'
import actions from './actions'

const createFeedback = async payload => apiClient.post(`feedbacks`, payload)
const getFeedbackAll = async payload => apiClient.get(`feedbacksGet`, { params: payload })

export function* POST_FEEDBACK({ payload }) {
  try {
    const { data, status } = yield call(createFeedback, payload)
    switch (status) {
      case 201:
        ntf.success({ message: "Thank you for your feedback" })
        yield put({ type: 'feedback/POST_FEEDBACK_SUCCESS', payload: { feedback: data.feedback }})
        break
      case 422:
        ntf.error({
          message: 'Error',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_FEEDBACKS_ALL({ payload }) {
  try {
    const { data, status } = yield call(getFeedbackAll, payload)
   
    switch (status) {
      case 200:
        // yield put({ type: 'feedback/GET_FEEDBACKS_ALL', payload: { data }})
        yield put({
          type: 'feedback/GET_FEEDBACKS_ALL_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.POST_FEEDBACK, POST_FEEDBACK),
    takeEvery(actions.GET_FEEDBACKS_ALL, GET_FEEDBACKS_ALL),
  ])
}
