/* eslint-disable */

const redRGB = 'f5222e';
const greenRGB = '4caf50';

const rExelColumns = [
    { title: "ASIN CODE", width: { wpx: 110 }, style: { font: { sz: "15", color: { rgb: greenRGB }, bold: true } } },
    { title: "", width: { wpx: 300 } }
];

const rDataSheet = [
    {
        columns: rExelColumns,
        data: [
        ]
    }
];

export const produceAcos = (inputs) => {
    let result = [];

    // const sortedSearches = inputs.sort((a, b) => a.page - b.page);

    // sortedSearches.forEach(bs => {
    //     bs.products.forEach(b => {
    //         if (b) {
    //             asins.push(b.asin);
    //         }
    //     });
    // });

    // asins = [...new Set(asins)]; // unique asins
    // asins = asins.splice(0, 1000); // get the first 1000

    // const sheet = { ...rDataSheet[0] };

    // for (const asin of asins) {
    //     sheet.data = [
    //         ...sheet.data,
    //         [
    //             { value: '' },
    //             { value: asin }
    //         ]
    //     ]
    // }

    return { result }
}

export default { produceAcos }