import actions from './actions'

const initialState = {
  loading: false,
  booksPayments: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    negative: 0,
    positive: 0,
    query:[],
  },
}


export default function booksPaymentsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
