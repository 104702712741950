const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  GET_LIST: 'user/GET_LIST',
  GET_LIST_ALL: 'user/GET_LIST_ALL',
  GET_LIST_SUCCESS: 'user/GET_LIST_SUCCESS',
  GET_ITEM: 'user/GET_ITEM',
  GET_ITEM_SUCCESS: 'user/GET_ITEM_SUCCESS',
  CREATE: 'user/CREATE',
  EDIT: 'user/EDIT',
  UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
  UPDATE_EMAIL: 'user/UPDATE_EMAIL',
  FAST_1K_AUTHORS: 'user/FAST_1K_AUTHORS',
  UPDATE_AVATAR: 'user/UPDATE_AVATAR',
  UPDATE_CONTACT: 'user/UPDATE_CONTACT',
  DELETE: 'user/DELETE',
  GET_USERS_SEARCHES: 'user/GET_USERS_SEARCHES',
  CLEAR_STATE: 'user/CLEAR_STATE',
  CREATE_RESET_PASSWORD: 'user/CREATE_RESET_PASSWORD',
  FIND_TOKEN_PASSWORD_RESET: 'user/FIND_TOKEN_PASSWORD_RESET',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  ADD_BANK_ACCOUNT: 'user/ADD_BANK_ACCOUNT',
  ADD_AVAILABLE_LESSONS: 'user/ADD_AVAILABLE_LESSONS',
  CREATE_KDP: 'user/CREATE_KDP',
  CREATE_PLACEMENT: 'user/CREATE_PLACEMENT',
  CREATE_TARGETING: 'user/CREATE_TARGETING',
  CREATE_FEEDING: 'user/CREATE_FEEDING',
  AUTH_LOG_LIST: 'user/AUTH_LOG_LIST',
  CHANGE_LANGUAGE: 'user/CHANGE_LANGUAGE',
}

export default actions
