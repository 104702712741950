import { isDev } from '../../constants'

export default async function getMenuData() {
  return [
    {
      title: 'Scan',
      key: 'Scan',
      icon: 'lnr lnr-rocket',
      url: '/dashboard/select-service',
      privileges: ['scan'],
      roles: ['Administrator', 'Client'],
    },
    // {
    //   title: 'Your subscriptions',
    //   key: 'subscriptions',
    //   icon: 'lnr lnr-cart',
    //   url: '/dashboard/subscriptions',
    //   roles: ['Administrator'],
    // },
    {
      title: 'Subscriptions and Payments',
      key: 'subscription',
      icon: 'lnr lnr-cart',
      url: '/dashboard/subscriptions/show',
      roles: ['Client', 'Administrator'],
    },
    {
      title: 'Users',
      key: 'users',
      icon: 'fe fe-users',
      url: '/dashboard/users',
      roles: ['Administrator'],
    },
    {
      title: 'Users Activity',
      key: 'users-activity',
      icon: 'fe fe-users',
      url: '/dashboard/users-activity',
      roles: ['Administrator'],
    },
    {
      title: 'Feedbacks',
      key: 'feedbacks',
      icon: 'fe fe-message-square',
      url: '/dashboard/feedbacks',
      roles: ['Administrator'],
    },
    {
      title: 'Settings',
      key: 'Settings',
      icon: 'lnr lnr-cog',
      roles: ['Administrator'],
      children: [
        {
          title: 'App Settings',
          key: 'scanSettings',
          url: '/dashboard/settings/scan-settings',
        },
        {
          title: 'Notifications',
          key: 'notifications',
          url: '/dashboard/settings/notifications',
        },
        {
          title: 'Prices',
          key: 'prices',
          url: '/dashboard/settings/prices',
          icon: 'lnr lnr-shop',
        },
        {
          title: 'Payments',
          key: 'payments',
          url: '/dashboard/settings/payments',
        },
        {
          title: 'Searches',
          key: 'searches',
          url: '/dashboard/settings/searches',
          icon: 'lnr lnr-search',
        },
        {
          title: 'Software Data',
          key: 'Software Data',
          icon: 'lnr lnr-pie-chart',
          url: '/dashboard/software-data',
        },
        {
          title: 'IP Reports',
          key: 'ip-reports',
          url: '/dashboard/settings/ip-reports',
        },
        {
          title: 'Logs',
          key: 'Logs',
          url: '/dashboard/settings/logs',
        },
        {
          title: 'Ads Robot Logs',
          key: 'booksPayments',
          url: '/dashboard/ads-robot/history',
        },
      ]
    },
    {
      title: 'Profile',
      key: 'Profile',
      icon: 'lnr lnr-user',
      url: '/dashboard/profile',
      roles: ['Administrator', 'Client'],
    },
    {
      title: 'Affiliate Program',
      key: 'Affiliate',
      icon: 'lnr lnr-link',
      url: '/dashboard/affiliate-registration',
      roles: ['Administrator', 'Client']
    },
    {
      title: 'Lessons',
      key: 'Lessons',
      icon: 'lnr lnr-book',
      url: '/dashboard/lessons'
    },
    {
      title: 'Tutorials',
      key: 'tutorials',
      icon: 'lnr lnr-camera-video',
      url: '/dashboard/tutorials'
    },
    {
      title: 'Bonus',
      key: 'bonus',
      icon: 'lnr lnr-gift',
      url: '/dashboard/bonus'
    },
    {
      title: 'My Searches',
      key: 'Searches',
      icon: 'lnr lnr-magnifier',
      url: '/dashboard/my-searches',
      roles: ['Client'],
    },
    // {
    //   title: 'My Books',
    //   key: 'books',
    //   icon: 'lnr lnr-list',
    //   url: '/dashboard/my-books',
    //   roles: ['Client']
    // },
    {
      title: 'My Books',
      key: 'books-new',
      icon: 'lnr lnr-list',
      url: '/dashboard/my-books-new',
      roles: ['Client', 'Administrator']
    },

    {
      title: 'Payments history',
      key: 'payments-logs',
      icon: 'far fa fa-history',
      url: '/dashboard/payments-logs',
      roles: ['Administrator', 'Client']
    },
    {
      title: 'Shop',
      key: 'Shop',
      icon: 'lnr lnr-cart',
      url: '/dashboard/shop',
      roles: ['Administrator', 'Client']
    },
    {
      title: 'Ads Robot',
      key: 'Ads Robot',
      icon: 'lnr lnr-magic-wand',
      url: '/dashboard/ads-robot',
      roles: ['Administrator', 'Client'],
      children: [
        {
          title: 'Functions List',
          key: 'activationCosts',
          url: '/dashboard/ads-robot/activationCosts',
        },
        {
          title: 'Onboarding',
          key: 'onboarding',
          url: '/dashboard/ads-robot',
        },
        {
          title: 'Progressive Bid Increase',
          key: 'bidIncrease',
          url: '/dashboard/ads-robot/bidIncrease',
        },
        {
          title: 'Placements&Auto Optimization',
          key: 'placements',
          url: '/dashboard/ads-robot/placements',
          icon: '',
        },
        {
          title: 'Targeting Optimization',
          key: 'target',
          url: '/dashboard/ads-robot/targeting',
          icon: '',
        },
        {
          title: 'Search Terms Feeding',
          key: 'searchTerms',
          // url: '#',
          url: '/dashboard/ads-robot/feeding',
          icon: '',
          // disabled: true
        },
        {
          title: 'Colored Books ASINs',
          key: 'history',
          url: '/dashboard/ads-robot/coloredBooks',
          icon: '',
        },
        {
          title: 'History',
          key: 'history',
          url: '/dashboard/ads-robot/history',
          icon: '',
        },
        {
          title: 'Tutorials',
          key: 'history',
          url: '/dashboard/ads-robot/tutorials',
          icon: '',
        },
    ]
    },
    // {
    //   title: 'Targeting Optimization',
    //   key: 'targeting_admin',
    //   icon: '',
    //   url: '/dashboard/ads-robot/targeting',
    //   roles: ['Administrator']
    // },
    // {
    //   title: 'Select Service',
    //   key: 'select-service',
    //   icon: 'lnr lnr-service',
    //   url: '/dashboard/select-service',
    // },
    // {
    //   title: 'Widgets',
    //   key: 'widgets',
    //   icon: 'fe fe-image',
    //   count: 47,
    //   children: [
    //     {
    //       title: 'General',
    //       key: 'widgetsGeneral',
    //       url: '/widgets/general',
    //     },
    //     {
    //       title: 'Lists',
    //       key: 'widgetsLists',
    //       url: '/widgets/lists',
    //     },
    //     {
    //       title: 'Tables',
    //       key: 'widgetsTables',
    //       url: '/widgets/tables',
    //     },
    //     {
    //       title: 'Charts',
    //       key: 'widgetsCharts',
    //       url: '/widgets/charts',
    //     },
    //   ],
    // },
    // {
    //   title: 'Cards',
    //   key: 'cards',
    //   icon: 'fe fe-credit-card',
    //   children: [
    //     {
    //       title: 'Basic Cards',
    //       key: 'cardsBasicCards',
    //       url: '/cards/basic-cards',
    //     },
    //     {
    //       title: 'Tabbed Cards',
    //       key: 'cardsTabbedCards',
    //       url: '/cards/tabbed-cards',
    //     },
    //   ],
    // },
    // {
    //   title: 'Tables',
    //   key: 'tables',
    //   icon: 'fe fe-grid',
    //   children: [
    //     {
    //       title: 'Ant Design',
    //       key: 'tablesAntd',
    //       url: '/tables/antd',
    //     },
    //     {
    //       title: 'Bootstrap',
    //       key: 'tablesBootstrap',
    //       url: '/tables/bootstrap',
    //     },
    //   ],
    // },
    // {
    //   title: 'Charts',
    //   key: 'charts',
    //   icon: 'fe fe-pie-chart',
    //   children: [
    //     {
    //       title: 'Chartist.js',
    //       key: 'chartsChartistjs',
    //       url: '/charts/chartistjs',
    //     },
    //     {
    //       title: 'Chart.js',
    //       key: 'chartsChartjs',
    //       url: '/charts/chartjs',
    //     },
    //     {
    //       title: 'C3',
    //       key: 'chartsC3',
    //       url: '/charts/c3',
    //     },
    //   ],
    // },
    // {
    //   title: 'Icons',
    //   key: 'icons',
    //   icon: 'fe fe-star',
    //   children: [
    //     {
    //       title: 'Feather Icons',
    //       key: 'iconsFeatherIcons',
    //       url: '/icons/feather-icons',
    //     },
    //     {
    //       title: 'Fontawesome',
    //       key: 'iconsFontawesome',
    //       url: '/icons/fontawesome',
    //     },
    //     {
    //       title: 'Linearicons Free',
    //       key: 'iconsLineariconsFree',
    //       url: '/icons/linearicons-free',
    //     },
    //     {
    //       title: 'Icomoon Free',
    //       key: 'iconsIcomoonFree',
    //       url: '/icons/icomoon-free',
    //     },
    //   ],
    // },
  ]
}
