import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, notification as ntf } from 'antd';
import apiClient from 'services/axios'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import {store} from "../../../../index";
import styles from "../../../../pages/shop/shop.module.scss"


const clearNotifications = async () => apiClient.post(`clearNotifications`);
const clearSingleNotification = async (data) => apiClient.post(`clearSingleNotification`, {id:data});

const { Text, Title } = Typography;

const TopBar = () => {
  const settings = useSelector(state => state.settings)

  const [showNotification, setShowNotification] = useState(false);

  const { points = 0, optimizeLogs } = useSelector(state => state.user)
  const [notificationsRead, setNotificationsRead] = useState(false);

  const handleBellIconClick = () => {
    if (optimizeLogs && !showNotification) {
      optimizeLogs.forEach((log) => {
        if (log.notification) {
          ntf.success({
            message: log.title,
            description: log.message,
            duration: 500,
            onClose: () => {
              clearSingleNotification(log.id);
            },
          });
        } else {
          ntf.success({
            message: log.service,
            description: `${log.name} successfully optimized ${log.date}!`,
            duration: 500,
            onClose: () => {
              clearSingleNotification(log.id);
            },
          });
        }
      });
    } else {
      store.dispatch({ type: "user/LOAD_CURRENT_ACCOUNT" })
      ntf.destroy();
    }
    setShowNotification(!showNotification);
  };
  const handleArchiveIconClick = () => {
    setShowNotification(false);
    setNotificationsRead(true);
    store.dispatch({ type: "user/LOAD_CURRENT_ACCOUNT" })
    ntf.destroy();
    clearNotifications();
    ntf.success({
      message: 'Notification archived!',
      duration: 200,
    });
  };
  const handleBellIconKeyPress = () => {
    handleBellIconClick();
  };

  return (
    <div className={style.topbar}>
      <Title level={5} className={settings.isMobileView ? "pt-3 pl-5" : "pt-3"}>
        <Text className={points > 0 ? "text-primary" : "text-danger"}>
          {points} Fast Coins

          <img
          src="resources/images/icon_coins.png"
          alt="logo"
          className={styles.shopLogoNew}
          />
        </Text>
      </Title>
      <div className="ml-auto" style={{ position: 'relative'}}>
       <span
         role="button"
         onClick={handleBellIconClick}
         onKeyDown={handleBellIconKeyPress}
         tabIndex={0}
         title="Notifications"
         className="fa fa-bell-o mr-2"
         style={{
           position: 'absolute',
           left: '-40px',
           fontSize: '1.5rem',
           bottom: '30%',
           cursor:'pointer',
           color: showNotification ? 'orange' : ''}}
       >
          {showNotification ? null : (
            <div style={{ background: 'red', borderRadius: '50%', width: '1.3rem', height: '1.1rem', textAlign: 'center', color: 'white', fontWeight: 'bold', position: 'absolute', top: '-5px', right: '-5px', fontSize: '1rem' }}>
              {!notificationsRead ? optimizeLogs?.length : 0}
            </div>
          )}
       </span>
        {!showNotification ? null : (
      <span
        role="button"
        onClick={handleArchiveIconClick}
        onKeyDown={handleArchiveIconClick}
        tabIndex={0}
        className=""
        style={{ position: 'absolute', left: '-135px', fontSize: '1.5rem', bottom: '0', cursor:'pointer', 'white-space':'nowrap'}}
        title="Clear all notifications"
      >
       <button type="button" className="ant-btn ant-btn-default">
         <span>Clear All</span>
       </button>
      </span>
        )}
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar
