const actions = {
  SET_STATE: 'findAsins/SET_STATE',
  CLEAR_STATE: 'findAsins/CLEAR_STATE',
  SEARCH_BOOKS: 'findAsins/SEARCH_BOOKS',
  SEARCH_BOOKS_SUCCESS: 'findAsins/SEARCH_BOOKS_SUCCESS',
  GET_ASINS: 'findAsins/GET_ASINS',
  REQ_LOADED: 'findAsins/REQ_LOADED'
}

export default actions
