/* eslint-disable */

const redRGB = 'f5222e';
const greenRGB = '4caf50';

const rExelColumns = [
    { title: "Fast Products", width: { wpx: 110 }, style: { font: { sz: "15", color: { rgb: greenRGB }, bold: true } } },
    { title: "", width: { wpx: 300 } },
    { title: "Fast Products²", width: { wpx: 110 }, style: { font: { sz: "15", color: { rgb: greenRGB }, bold: true } } },
    { title: "", width: { wpx: 300 } },
    { title: "Removed Products", width: { wpx: 110 }, style: { font: { sz: "15", color: { rgb: redRGB }, bold: true } } },
    { title: "", width: { wpx: 300 } },
];

const rDataSheet = [
    {
        columns: rExelColumns,
        data: [
        ]
    }
];

export const generateData = (searchingResult, searchSettings) => {
    let removed = searchSettings?.removed?.length ? searchSettings.removed : [];
    let asins = [];
    let removedWords = [];

  let removedList = [];

  let sortedSearches = searchingResult.sort((a, b) => a.page - b.page);


    sortedSearches.forEach(bs => {
      // bs.products = bs.products.filter(function (item) {
      //   let removeKeyword = false;
      //   removed.map(x => {
      //     if (RegExp('\\b' + x + '\\b').test(item.title.toLowerCase())) {
      //       removedList.push(item.asin);
      //       removeKeyword = true;
      //     }
      //   })
      //   if (!removeKeyword) return item;
      // })
        bs.products.forEach(b => {
          let removeKeyword = false;
          removed.map(x => {
            if (RegExp('\\b' + x + '\\b').test(b.title.toLowerCase())) {
              removedList.push(b.asin);
              removeKeyword = true;
            }
          })
          if (removeKeyword) {
            return;
          }

            if (b) {
                asins.push(b.asin);
            }
        });
    });

    removedList = [...new Set(removedList)]; // unique asins
    removedList = removedList.splice(0, 2000); // max 2000 in total
    const asinsRemoved1000 = removedList.splice(0, 1000); // get the first 1000
    asins = [...new Set(asins)]; // unique asins
    asins = asins.splice(0, 2000); // max 2000 in total
    const asins1000 = asins.splice(0, 1000); // get the first 1000

    const sheet = { ...rDataSheet[0] };
    let asinsLength = asins1000;
    if (asins1000.length < asinsRemoved1000.length){
      asinsLength = asinsRemoved1000;
    }

    for (const asinIdx in asinsLength) {
        sheet.data = [
            ...sheet.data,
            [
                { value: '' },
                { value: asins1000[asinIdx] },
                { value: '' },
                { value: asins[asinIdx] },
                { value: '' },
                { value: asinsRemoved1000[asinIdx] }
            ]
        ]
    }

    return { rSheet: sheet, sortedSearches, asins: [...asins1000, ...asins], removed: [...asinsRemoved1000] }
}

export default { generateData }
