import actions from './actions'

const initialState = {
  loading: false,
  searchList: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    removed: 0,
    negative: 0,
    positive: 0,
    query:[],

  },
  searchItem: null,
}

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state

    case actions.DELETE: {
      const newData = state.searchList.data.filter(search => search.id !== action.payload);
      return {
        ...state,
        searchList: {
          ...state.searchList,
          data: newData,
          removed: state.searchList.removed + 1,
        },
      }}

    case actions.RESTORE: {
      const newData = state.searchList.data.filter(search => search.id !== action.payload);
      return {
        ...state,
        searchList: {
          ...state.searchList,
          data: newData,
          removed: state.searchList.removed - 1,
        },
      }}
  }
}
