import actions from './actions'

const initialState = {
  searchSettings: {
    id: '',
    keywords: [],
    departments: [],
    market: null
  },
  searchingResult: [],
  asins: [],
  scanNr: 0,
  scannedNr: 0,
  loading: false
}

export default function findAsinsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_STATE:
      return { ...initialState, ...action.payload }

    case actions.SEARCH_BOOKS:
      return {
        ...state, searchSettings: action.payload, loading: true
      }

    case actions.SEARCH_BOOKS_SUCCESS:
      return {
        ...state, searchingResult: [...state.searchingResult, action.data]
      }

    default:
      return state
  }
}
