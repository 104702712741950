
const actions = {
  SET_STATE: 'book/SET_STATE',
  GET_CHART: 'book/GET_CHART',
  UPDATE_DATA: 'book/UPDATE_DATA',
  GET_LIST: 'book/GET_LIST',
  RESET_DATA: 'book/RESET_DATA',
  GET_ITEM: 'book/GET_ITEM',
  GET_LANGUAGE: 'book/GET_LANGUAGE',
  CREATE: 'book/CREATE',
  EDIT: 'book/EDIT',
  DELETE: 'book/DELETE',
  REPEAT: 'book/REPEAT'
}

export default actions

