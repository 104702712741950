import { all, put, call, takeEvery } from 'redux-saga/effects'
import apiClient from 'services/axios'
import { notification as ntf } from 'antd'
import actions from './actions'

const clearAllTokens = async () => apiClient.post(`clearAllTokens`);
const sendHelpMessage = async (payload) => apiClient.post(`sendHelpMessage`, payload);
const getLogs = async (payload) => apiClient.get(`errors`, payload);
const getReport = async (payload) => apiClient.get(`report`, payload);

export function* CLEAR_ALL_TOKENS() {
  try {
    const { data, status } = yield call(clearAllTokens);
    if (status == 200) {
      ntf.success({ message: "Succcessfully cleared all sessions" })
    } else {
      ntf.error({ message: 'We were not able to clear the sessions!' })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* SEND_HELP_MESSAGE({ payload }) {
  try {
    const { status } = yield call(sendHelpMessage, payload);
    if (status == 200) {
      ntf.success({ message: "Message was send successfully" })
    } else {
      ntf.error({ message: 'We were not able to send the message!' })
    }
  }
  catch (error) {
    ntf.error({ message: 'Error: the message was not sent. Please try again' })
    console.log('error', error);
  }
}

export function* GET_LOGS({ payload }) {
  try {
    const { status, data } = yield call(getLogs, payload);
    if (status == 200) {
      yield put({
        type: 'app/SET_STATE',
        payload: {
          logs: data
        },
      })
    } else {
      ntf.error({ message: 'We were not able to get the data!' })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* GET_REPORT({ payload }) {
  try {
    const { status, data } = yield call(getReport, payload);
    if (status == 200) {
      yield put({
        type: 'app/SET_STATE',
        payload: {
          report: data
        },
      })
    } else {
      ntf.error({ message: 'We were not able to get the data!' })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CLEAR_ALL_TOKENS, CLEAR_ALL_TOKENS),
    takeEvery(actions.SEND_HELP_MESSAGE, SEND_HELP_MESSAGE),
    takeEvery(actions.GET_LOGS, GET_LOGS),
    takeEvery(actions.GET_REPORT, GET_REPORT),
  ])
}
