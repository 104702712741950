const actions = {
  SET_STATE: 'subscription/SET_STATE',
  GET_CURRENT_SUBSCRIPTION: 'subscription/GET_CURRENT_SUBSCRIPTION',
  GET_LIST: 'subscription/GET_LIST',
  GET_LIST_SUCCESS: 'subscription/GET_LIST_SUCCESS',
  GET_PRICES: 'subscription/GET_PRICES',
  GET_ITEM: 'subscription/GET_ITEM',
  GET_ITEM_SUCCESS: 'subscription/GET_ITEM_SUCCESS',
  CREATE: 'subscription/CREATE',
  EDIT: 'subscription/EDIT',
  DELETE: 'subscription/DELETE',
  GET_PAYMENTS: 'subscription/GET_PAYMENTS',
  PAYMENT_CREATE: 'subscription/PAYMENT_CREATE',
  PAYMENT_DELETE: 'subscription/PAYMENT_DELETE',
  GET_PAYMENT_ITEM: 'subscription/GET_PAYMENT_ITEM',
  CLEAR_STATE: 'subscription/CLEAR_STATE',
  VALIDATE_COUPON: 'subscription/VALIDATE_COUPON',
  GET_SHOP_PRICES: 'subscription/GET_SHOP_PRICES'
}

export default actions
