const actions = {
  SET_STATE: 'scan/SET_STATE',
  CLEAR_STATE: 'scan/CLEAR_STATE',
  SEARCH_KEYWORD: 'scan/SEARCH_KEYWORD',
  ADD_KEYWORD: 'scan/ADD_KEYWORD',
  UPDATE_KEYWORD: 'scan/UPDATE_KEYWORD',
  GET_SUGGESTIONS: 'scan/GET_SUGGESTIONS',
  GET_SUGGESTIONS_SUCCESS: 'scan/GET_SUGGESTIONS_SUCCESS',
  GET_AZ_LIST: 'scan/GET_AZ_LIST',
  GET_AZ_SUCCESS: 'scan/GET_AZ_SUCCESS',
  GET_AZ_ERROR: 'scan/GET_AZ_ERROR',
  SEARCH_SUCCESS: 'scan/SEARCH_SUCCESS',
  GET_BOOKS: 'scan/GET_BOOKS',
  GET_BOOK_SUCCESS: 'scan/GET_BOOK_SUCCESS',
  GET_BOOK_ERROR: 'scan/GET_BOOK_ERROR',
  REQ_LOADED: 'scan/REQ_LOADED',
  ADD_GKP_FILE: 'scan/ADD_GKP_FILE',
  EXECUTE_R: 'scan/EXECUTE_R'
}

export default actions
