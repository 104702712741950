import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { store as reduxStore } from 'index'
import { Link } from 'react-router-dom'

import styles from './style.module.scss'

const ProfileMenu = () => {
  const [count, setCount] = useState(7)
  const user = useSelector(state => state.user)

  let avatar = '/resources/images/avatars/avatar.png';
  if (user.avatar){
    avatar = user.avatar[0].data_url;
  }
  const logout = e => {
    e.preventDefault()
    reduxStore.dispatch({
      type: 'user/LOGOUT',
    })
    // window.localStorage.clear();
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>
          {user.firstName} {user.lastName}
        </strong>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.roles ? user.roles[0].name : '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/dashboard/profile" className="kit__utils__link font-size-16">
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Avatar className={styles.avatar} style={{ position: 'relative', overflow: 'hidden', borderRadius: '50%'}} shape="square" size="large" icon={<img alt="avatar" src={avatar} style={{width : '100%', height :'auto'}} />} />
      </div>
    </Dropdown>
  )
}

export default ProfileMenu
