import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect, useDispatch, useSelector } from 'react-redux'

import Layout from 'layouts'

const routes = [
  {
    path: '/',
    Component: lazy(() => import('pages/homepage')),
    exact: true,
  },
  {
    path: '/dashboard/lessons',
    Component: lazy(() => import('pages/lessons')),
    exact: true,
  },
  {
    path: '/dashboard/my-searches',
    Component: lazy(() => import('pages/my-searches')),
    exact: true,
  },
  {
    path: '/dashboard/my-books',
    Component: lazy(() => import('pages/my-books/index.jsx')),
    exact: true,
  },
  {
    path: '/dashboard/my-books-new',
    Component: lazy(() => import('pages/my-books/index-new.jsx')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot',
    Component: lazy(() => import('pages/autopilot')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/bidIncrease',
    Component: lazy(() => import('pages/autopilot/components/bidIncrease.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/targeting',
    Component: lazy(() => import('pages/autopilot/components/targeting.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/feeding',
    Component: lazy(() => import('pages/autopilot/components/feeding.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/placements',
    Component: lazy(() => import('pages/autopilot/components/placements.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/coloredBooks',
    Component: lazy(() => import('pages/autopilot/components/coloredBooks.js')),
    exact: true,
  },
  {
    path: '/dashboard/payments-logs',
    Component: lazy(() => import('pages/payments/payments-logs/history')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/history',
    Component: lazy(() => import('pages/autopilot/components/history.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/activationCosts',
    Component: lazy(() => import('pages/autopilot/components/payments.js')),
    exact: true,
  },
  {
    path: '/dashboard/ads-robot/tutorials',
    Component: lazy(() => import('pages/autopilot/components/tutorials.js')),
    exact: true,
  },
  {
    path: '/dashboard/view-components',
    Component: lazy(() => import('pages/view-components')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/dashboard/subscriptions',
    Component: lazy(() => import('pages/subscriptions/list.js')),
    exact: true,
  },
  {
    path: '/dashboard/subscriptions/show',
    Component: lazy(() => import('pages/subscriptions/show.js')),
    exact: true,
  },
  {
    path: '/dashboard/subscriptions/create',
    Component: lazy(() => import('pages/subscriptions/create.js')),
    exact: true,
  },
  {
    path: '/dashboard/select-service',
    Component: lazy(() => import('pages/select-service')),
    exact: true,
  },
  {
    path: '/dashboard/scan',
    Component: lazy(() => import('pages/scan')),
    exact: true,
  },
  {
    path: '/dashboard/fast-products',
    Component: lazy(() => import('pages/find-asins')),
    exact: true,
  },
  {
    path: '/dashboard/acos',
    Component: lazy(() => import('pages/acos')),
    exact: true,
  },
  {
    path: '/dashboard/fast-phrases',
    Component: lazy(() => import('pages/fast-phrases/index.js')),
    exact: true,
  },
  {
    path: '/dashboard/shop',
    Component: lazy(() => import('pages/shop/index.js')),
    exact: true,
  },
  {
    path: '/dashboard/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/dashboard/affiliate-registration',
    Component: lazy(() => import('pages/affiliation/affliate-registration.jsx')),
    exact: true,
  },
  {
    path: '/dashboard/affiliation',
    Component: lazy(() => import('pages/affiliation/index.jsx')),
    exact: true,
  },
  {
    path: '/dashboard/affiliation/:page',
    Component: lazy(() => import('pages/affiliation/index.jsx')),
    exact: true,
  },
  {
    path: '/dashboard/software-data',
    Component: lazy(() => import('pages/softwareData')),
    exact: true,
  },
  {
    path: '/dashboard/tutorials',
    Component: lazy(() => import('pages/tutorials')),
    exact: true,
  },
  {
    path: '/dashboard/Bonus',
    Component: lazy(() => import('pages/bonus')),
    exact: true,
  },
  {
    path: '/dashboard/users',
    Component: lazy(() => import('pages/users')),
    exact: true,
  },
  {
    path: '/dashboard/users-activity',
    Component: lazy(() => import('pages/users-activity')),
    exact: true,
  },
  {
    path: '/dashboard/feedbacks',
    Component: lazy(() => import('pages/feedbacks')),
    exact: true,
  },
  {
    path: '/dashboard/users/:id/show',
    Component: lazy(() => import('pages/users/single')),
    exact: true,
  },
  {
    path: '/dashboard/users/create',
    Component: lazy(() => import('pages/users/create')),
    exact: true,
  },
  {
    path: '/dashboard/settings/scan-settings',
    Component: lazy(() => import('pages/settings/scan-settings')),
    exact: true,
  },
  {
    path: '/dashboard/settings/logs',
    Component: lazy(() => import('pages/logs/index')),
    exact: true,
  },
  {
    path: '/dashboard/settings/notifications',
    Component: lazy(() => import('pages/settings/notifications')),
    exact: true,
  },
  {
    path: '/dashboard/settings/booksPayments',
    Component: lazy(() => import('pages/settings/books-logs')),
    exact: true,
  },
  {
    path: '/dashboard/settings/prices',
    Component: lazy(() => import('pages/settings/prices')),
    exact: true,
  },
  {
    path: '/dashboard/settings/payments',
    Component: lazy(() => import('pages/settings/payments')),
    exact: true,
  },
  {
    path: '/dashboard/settings/searches',
    Component: lazy(() => import('pages/settings/searches/searchesList')),
    exact: true,
  },
  {
    path: '/dashboard/settings/searches/:id/show',
    Component: lazy(() => import('pages/settings/searches/searchSingle')),
    exact: true,
  },
  {
    path: '/dashboard/settings/ip-reports',
    Component: lazy(() => import('pages/settings/ipReports')),
    exact: true,
  },
  // cart
  {
    path: '/dashboard/orders',
    Component: lazy(() => import('pages/orders')),
    exact: true,
  },
  {
    path: '/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/change-password/:token',
    Component: lazy(() => import('pages/auth/change-password')),
    exact: true,
  },
  // {
  //   path: '/auth/forgot-password',
  //   Component: lazy(() => import('components/auth/forgot-password/index.js')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/forgot-password',
  //   Component: lazy(() => import('components/auth/forgot-password/change-password')),
  //   exact: true,
  // },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('components/auth/register')),
  //   exact: true,
  // },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  const dispatch = useDispatch();
  const { user, router } = useSelector(state => state);

  useEffect(() => {
    const localVersion = localStorage.getItem('version');
    const versionNow = process.env.REACT_APP_VERSION;
    if (!localVersion || localVersion !== versionNow) {
      console.log('got new version', `from ${localVersion} to ${versionNow}`);
      dispatch({ type: 'scan/CLEAR_STATE' });
      localStorage.setItem('version', versionNow);
      window.location.href = window.location.href;
    }

    if (user.token) {
      dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT' });
    }
  }, [])

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/dashboard" />} /> */}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
