import { all, takeEvery, put, call, delay } from 'redux-saga/effects'
import { notification } from 'antd'
import { history, store as reduxStore } from 'index'
import apiClient from 'services/axios'
import ReactPixel from 'react-facebook-pixel';
// import React from "react";
import actions from './actions'
import { items as shopPrices } from '../../pages/shop/items'


const advancedMatching = { em: 'some@email.com' };
const options = {
  autoConfig: true,
  debug: false,
};

const getCurrentSubscription = async () => apiClient.get(`subscribe`);
const getList = async () => apiClient.get(`subscription`)
const getPrices = async () => apiClient.get(`prices`)
const getPayments = async (payload) => apiClient.get(`payments`, { params: payload })
const createPayment = async payload => apiClient.post(`payments`, payload)
const deletePayment = async payload => apiClient.delete(`payments/${payload}`)
const getPaymentItem = async (id) => apiClient.get(`payments/${id}`)
const getItem = async (id) => apiClient.get(`subscription/${id}`)
const createSubscription = async payload => apiClient.post(`subscribe`, payload)
const editSubscription = async payload => apiClient.put(`subscription/${payload.id}`, payload)
const deleteSubscription = async payload => apiClient.delete(`subscription/${payload.id}`)
const validateCoupon = async coupon => apiClient.get(`coupon`, { params: coupon })
const getShopPrices = async () => apiClient.get(`shop-prices`)

export function* GET_CURRENT_SUBSCRIPTION() {
  try {
    yield put({ type: 'subscription/SET_STATE', payload: { loading: true } });
    const { data, status } = yield call(getCurrentSubscription)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/SET_STATE',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
  yield put({ type: 'subscription/SET_STATE', payload: { loading: false } });
}

export function* GET_ITEM({ id }) {
  try {
    const { data, status } = yield call(getItem, id)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/GET_ITEM_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* GET_PRICES() {
  try {
    const { data, status } = yield call(getPrices)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/SET_STATE',
          payload: { prices: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_LIST() {
  try {
    const { data, status } = yield call(getList)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/GET_LIST_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE({ payload }) {
  yield put({ type: 'subscription/SET_STATE', payload: { loading: true } });
  try {
    const { data, status } = yield call(createSubscription, payload)
    switch (status) {
      case 201:
        notification.error({
          message: 'We are not able to preceed with the payment',
          description: data,
        })
        break
      case 200:
        notification.success({
          message: 'The payment is successfully completed!',
          description: 'You will be able to use the below services',
        });
        ReactPixel.init('581409416335279', advancedMatching, options);
        ReactPixel.track('track', 'Purchase', {currency: "EUR", value: Number(payload.selectedPrice.unit_amount / 100).toFixed(2)});
        // console.log('Purchase', {currency: "EUR", value: Number(payload.selectedPrice.unit_amount / 100).toFixed(2)})
        // console.log(payload.selectedPrice.id)
        if (shopPrices[1].pricesCodes.find((x) => x.id == payload.selectedPrice.id || x.testId == payload.selectedPrice.id)) {
          window.location.replace("https://www.fastbookads.com/fast-key/");
        } else if (payload.selectedPrice.id == 'price_1NOiCfFj6Ixx0g7KffcfBhML') {
          history.push('/dashboard/ads-robot');
        } else {
          history.push('/dashboard/select-service');
        }

        yield put({ type: 'subscription/SET_STATE', payload: { loading: false } });
        yield delay(5000);
        window.location.reload();
        yield put({
          type: 'user/LOAD_CURRENT_ACCOUNT'
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
  yield put({ type: 'subscription/SET_STATE', payload: { loading: false } });
}

export function* EDIT({ payload }) {
  try {
    const { data, status } = yield call(editSubscription, payload)
    switch (status) {
      case 200:
        notification.success({
          message: 'The subscription is updated Succesfully',
          description: '',
        })
        yield put({
          type: 'subscription/GET_ITEM_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE({ payload }) {
  try {
    const { data, status } = yield call(deleteSubscription, payload)
    switch (status) {
      case 200:
        notification.success({
          message: 'The subscription is deleted Successfully',
          description: '',
        })
        yield history.push(`/dashboard/subscriptions`)
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_PAYMENTS({ payload }) {
  try {
    const { data, status } = yield call(getPayments, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/SET_STATE',
          payload: { paymentsList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_PAYMENT_ITEM({ id }) {
  try {
    const { data, status } = yield call(getPaymentItem, id)
    switch (status) {
      case 200:
        yield put({
          type: 'subscription/SET_STATE',
          payload: { paymentItem: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* PAYMENT_CREATE({ payload }) {
  yield put({ type: 'subscription/SET_STATE', payload: { loading: true } });
  try {
    const { data, status } = yield call(createPayment, payload)
    switch (status) {
      case 201:
        notification.success({
          message: 'The payment is successfully created!',
          description: '',
        });
        yield put({ type: 'user/GET_ITEM' });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
  yield put({ type: 'subscription/SET_STATE', payload: { loading: false } });
}

export function* PAYMENT_DELETE({ payload }) {
  try {
    const { data, status } = yield call(deletePayment, payload)
    switch (status) {
      case 200:
        notification.success({
          message: 'The payment is deleted Successfully',
          description: '',
        })
        yield put({ type: 'user/GET_ITEM' });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* VALIDATE_COUPON({ couponCode }) {
  try {
    const { data, status } = yield call(validateCoupon, { coupon: couponCode })
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          coupon: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}


export function* GET_SHOP_PRICES() {
  try {
    const { data, status } = yield call(getShopPrices)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          shopPrices: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_CURRENT_SUBSCRIPTION, GET_CURRENT_SUBSCRIPTION),
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_PRICES, GET_PRICES),
    takeEvery(actions.GET_ITEM, GET_ITEM),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.PAYMENT_DELETE, PAYMENT_DELETE),
    takeEvery(actions.GET_PAYMENTS, GET_PAYMENTS),
    takeEvery(actions.GET_PAYMENT_ITEM, GET_PAYMENT_ITEM),
    takeEvery(actions.PAYMENT_CREATE, PAYMENT_CREATE),
    takeEvery(actions.VALIDATE_COUPON, VALIDATE_COUPON),
    takeEvery(actions.GET_SHOP_PRICES, GET_SHOP_PRICES)
  ])
}
