import { all } from 'redux-saga/effects'
import app from './app/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import scan from './scan/sagas'
import findAsins from './findAsins/sagas'
import subscription from './subscription/sagas'
import searches from './searches/sagas'
import books from './books/sagas'
import autopilots from './autopilots/sagas'
import acos from './acos/sagas'
import fastPhrases from './fast-phrases/sagas'
import affiliate from './affiliate/sagas'
import booksPayments from './booksPayments/sagas'
import paymentsHistory from './paymentsHistory/sagas'
import notifications from './notifications/sagas'
import feedbacks from './feedbacks/sagas'

export default function* rootSaga() {
  yield all([app(), user(), menu(), settings(), scan(), findAsins(), subscription(), searches(), acos(), fastPhrases(), affiliate(), books(), autopilots(), booksPayments(), paymentsHistory(), notifications(), feedbacks()])
}
