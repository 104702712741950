/* eslint-disable no-unused-expressions */
import { jsonToCsvFile } from './csv'

export const addComas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const filterSuggestions = (props) => {
    const { newKeywordsList, keyword } = props;
    const optimizedList = [];
    try {
        newKeywordsList.forEach(x => {
            const str = x.toLowerCase(); // turn to lower case
            const idx = str.indexOf(keyword); // index of keyword on string

            if (idx >= 0) { // if is found
                const elBefore = str[idx - 1]; // element before the keyword
                const elAfter = str[keyword.length]; // element after the kyword

                if ( // add the conditions
                    (!elBefore && elAfter == ' ') ||
                    (elBefore == ' ' && !elAfter) ||
                    (elBefore == ' ' && elAfter == ' ') ||
                    (!elBefore && !elAfter)
                ) {
                    optimizedList.push(str);
                }
            }
        })
        return optimizedList;
    } catch (error) {
        console.log(error);
        return newKeywordsList;
    }
};

export const filterSuggestionsAll = (suggestions, keyword) => {
  const uniqueValues = new Set(); // To keep track of unique values
  return suggestions
    .map(x => {
      const str = x.value.toLowerCase();
      const idx = str.indexOf(keyword);

      if (idx >= 0) {
        const elBefore = str[idx - 1];
        const elAfter = str[idx + keyword.length];

        if (
          (!elBefore && elAfter === ' ') ||
          (elBefore === ' ' && !elAfter) ||
          (elBefore === ' ' && elAfter === ' ') ||
          (!elBefore && !elAfter)
        ) {
          // Check if the value is unique
          if (!uniqueValues.has(str)) {
            uniqueValues.add(str);
            return {
              value: str,
              az: x.az
            };
          }
        }
      }

      return null;
    })
    .filter(item => item !== null); // Filter out null entries
};

export const filterSuggestionsAZ = (props) => {
    const { newKeywordsList, keyword } = props;
    const optimizedList = [];
    try {
        newKeywordsList.forEach(x => {
            const str = x.toLowerCase(); // turn to lower case
            const idx = str.indexOf(keyword); // index of keyword on string

            if (idx >= 0 && x !== keyword) { // if is found
                optimizedList.push(str);
            }
        })
        return optimizedList;
    } catch (error) {
        console.log(error);
        return newKeywordsList;
    }
};

export const getResultDetails = (searchingResult) => {
    const allKeywordsList = [];
    const mainKeywords = [];
    let bookTitles = 0;
    let bookAuthors = 0;
    let suggKwds = 0;
    let suggAZKwds = 0;

    searchingResult.forEach(search => {
        mainKeywords.push(search.keyword);
        search.suggestions.forEach(suggObj => {
            suggKwds += 1;
            allKeywordsList.push({
                title: suggObj.value.toLowerCase(),
                type: 'Amazon'
            })
            if (suggObj.az) {
                Object.keys(suggObj.az)?.forEach(suggAzKey => {
                    suggObj.az[suggAzKey]?.forEach(azKeyword => {
                        suggAZKwds += 1;
                        allKeywordsList.push({
                            title: azKeyword.toLowerCase(),
                            type: 'Amazon',
                            suggType: true
                        })
                    })
                })
            }
        })
        Object.keys(search.books).forEach(bookDprtKey => {
            Object.keys(search.books[bookDprtKey]).forEach(bookPageKey => {
                search.books[bookDprtKey][bookPageKey]
                    .forEach(bookKeyword => {
                        if (bookKeyword.type == "Author Name") {
                            bookAuthors += 1;
                        } else {
                            bookTitles += 1;
                        }
                        allKeywordsList.push(bookKeyword)
                    })
            })
        })
    })

    const booksKeywordsTotal = bookTitles + bookAuthors;
    const total = booksKeywordsTotal + suggKwds + suggAZKwds;

    const amzCSVdata = generateAmazonCSVData(allKeywordsList);
    const amazonFile = amzCSVdata && createAmzFile(amzCSVdata);
    const kwPrincFile = createKwPrincFile(mainKeywords);

    return {
        allKeywordsList,
        amzCSVdata,
        amazonFile,
        kwPrincFile,
        booksKeywordsTotal,
        bookTitles,
        bookAuthors,
        suggKwds,
        suggAZKwds,
        total
    };
}

/* eslint-disable */
export const createKwPrincFile = (keywords) => {
    const keywordPrinc = [];
    for (const idx in keywords) {
        keywordPrinc.push({
            "PRINCIPALI": keywords[idx],
            "ROOT": ""
        });
    }

    return jsonToCsvFile({
        data: keywordPrinc,
        filename: 'kwprinc',
        addBom: false,
    });
}

export const generateAmazonCSVData = (data) => {
    let csvData = [];
    for (const idx in data) {
        const keyword = data[idx];
        if (keyword.type === 'Amazon') {
            csvData.push({
                "TITLE": keyword.title,
                "AUTHOR": '',
                "ASIN/ISBN": '',
                "TYPE": keyword.suggType ? 'Amazon A to Z Suggestions' : idx == 0 ? '-' : 'Amazon Suggestions'
            });
        }

        if (keyword.type === 'Book Title') {
            csvData.push({
                "TITLE": keyword.title,
                "AUTHOR": keyword.author || '',
                "ASIN/ISBN": '',
                "TYPE": 'Search Results'
            });
        }
    }
    return csvData;
}

export const createAmzFile = (csvData) => {
    return jsonToCsvFile({
        data: csvData,
        fieldWrapper: '"',
        addBom: true,
        filename: 'ams keyword search'
    })
}

export const formatPrice = (number) => {
    return `€ ${Number(number / 100).toFixed(2)}`
}
export const formatPriceNew = (number) => {
  return `€${Number(number / 100).toFixed(2)}`
}

export const chunks = function (array, size) {
    const results = [];
    while (array.length) {
        results.push(array.splice(0, size));
    }
    return results;
};

export const cleanKeyword = (x) => {
    x = x.toLowerCase()
    x = x.trim()
    x = x.replace(/\./g, '').replace(/\//g, "")
    return x;
}

export const isValidWord = (x) => {
    let validated = cleanKeyword(x)
    return validated.length > 1
};

export const splitGenerateWords = (v) => {
    let string = v
    let wordsToPut = [];
    if (string.includes(';') || string.includes(',') || string.includes('\n')) {
        string = string.replaceAll(',', ';');
        string = string.split('\n');
        string = string.join(';');
        wordsToPut = string.split(';').map(cleanKeyword).filter(isValidWord);
    } else if (isValidWord(string)){
        wordsToPut = [cleanKeyword(string)];
    };
    return wordsToPut
}

const index = { addComas, cleanKeyword };

export default index;
