import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import uuid from 'react-uuid';
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import { history } from 'index'
import style from './style.module.scss'
import {isDev} from "../../../../../constants";
/* eslint-disable */

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.roles ? user.roles[0].name : null,
  user: user,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
  user
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])


  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateMenuItems = () => {
    const generateItem = item => {
      const { key, title, url, icon, disabled, count } = item
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={item.title} />
      }
      if (item.url) {
        return (
          <Menu.Item key={uuid()} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          <span className={style.title}>{title}</span>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={style.title}>{menuItem.title}</span>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            </span>
          )
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return menuData.map(menuItem => {
      if (menuItem.title === 'Bonus'){
        if (user.planName !== 'year' && user.planName !== 'lifetime'){
          return null;
        }
      }

      if (menuItem.title === 'Ads Robot'){
        if (user.planName !== 'year' && user.planName !== 'lifetime'){
          return null;
        }
        if (user.id == 370 ||
            // user.id == 54 ||
            // user.id == 199 ||
            // user.id == 369 ||
            user.id == 152 ||
            // user.id == 59 ||
            // user.id == 102 ||
            // user.id == 253 ||
            user.id == 132 ||
            // user.id == 251 ||
            user.id == 84 ||
            user.id == 556
            // user.id == 454
      ){
          return null
        }
        // if (!isDev){
        //   if (user.id !== 157 && user.id !== 47 && user.id !== 102 && user.id !== 519 && user.id !== 500 &&
        //     // user.id !== 498 &&  //dev
        //     role !== 'Administrator'){
        //     return null
        //   }
        // }

      }

      if (menuItem.title === 'Users Activity'){
        if (user.id === 186 || user.id === 102 || user.id === 396){
          return generateItem(menuItem)
        }
      }
      // if (menuItem.title === 'Targeting Optimization'){
      //   if (user.id === 102 || user.id === 1 || user.id === 63 || user.id === 519){
      //     return generateItem(menuItem)
      //   }
      //   return null
      // }

      if (menuItem.title === 'My Books'){
        if (user.id == 370 ||
          // user.id == 54 ||
          // user.id == 199 ||
          // user.id == 369 ||
          user.id == 152 ||
          // user.id == 59 ||
          // user.id == 102 ||
          // user.id == 253 ||
          user.id == 132 ||
          // user.id == 251 ||
          user.id == 84 ||
          user.id == 556
          // user.id == 454
        ){
          return null
        }

        if (user.planName !== 'year' && user.planName !== 'lifetime'){
          return null;
        }
        if (user.booksSection){
          return generateItem(menuItem)
        }
        // if (!isDev){
        //   if (user.id !== 157 && user.id === 102 || user.id === 454 || user.id === 186 || user.id === 58){
        //     return generateItem(menuItem)
        //   }
        // }

        if (user?.kdp?.length > 0){
          return generateItem(menuItem)
        }
      }
      if (menuItem.title === 'Payments history'){
        if (user.id == 370 ||
          // user.id == 54 ||
          // user.id == 199 ||
          // user.id == 369 ||
          user.id == 152 ||
          // user.id == 59 ||
          // user.id == 102 ||
          // user.id == 253 ||
          user.id == 132 ||
          // user.id == 251 ||
          user.id == 84 ||
          user.id == 556
          // user.id == 454
        ){
          return null
        }

        if (user.planName !== 'year' && user.planName !== 'lifetime'){
          return null;
        }

        if (user?.kdp?.length > 0){
          return generateItem(menuItem)
        }
      }
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = menuItem.title === 'Ads Robot' ? (
          <span key={menuItem.key}>
      <span className={style.title}><img role="button" src="resources/images/adsRobot.png" style={{ maxWidth: '90%', marginLeft: '-5px' }} /></span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
    </span>
        ) : (
          <span key={menuItem.key}>
      <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
    </span>
        );

        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }

      return generateItem(menuItem)
    })
  }

  const menuSettings = isMobileView
    ? {
      width: leftMenuWidth,
      collapsible: false,
      collapsed: false,
      onCollapse,
    }
    : {
      width: leftMenuWidth,
      collapsible: true,
      collapsed: isMenuCollapsed,
      onCollapse,
      breakpoint: 'lg',
    }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={`logoMain ${style.logoContainer} ${isMenuCollapsed && 'pl-2'}`}>
          <a href="/#/dashboard/select-service">
          <div className={style.logo}>
              <img
              role="button"
              src={`resources/images/${isMenuCollapsed ? 'Logo-Fastbookads' : user.theme == 'white'?'logo-black': 'logo'}.png`}
              className="mr-2"
              onClick={() => history.push("/dashboard/select-service")}
              alt="Fast Book Ads"
              style={{ maxWidth: isMenuCollapsed ? 60 : 170 }}
            />
          </div>
          </a>
        </div>
        <PerfectScrollbar>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
