import actions from './actions'

const initialState = {
  devmode: false,
  searchSettings: {
    id: '',
    service: '',
    keywords: [],
    market: null,
    phrases: [],
    isCookBook: false
  },
  gkpArray: [],
  searchingResult: [],
  scanNr: 0,
  scannedNr: 0,
  keywordScanned: 0,
  rResult: null,

  rIsRunning: false,
  loading: false,

  runR: false,
}

export default function scanReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_STATE:
      return { ...initialState, ...action.payload }

    case actions.SEARCH_KEYWORD:
      return {
        ...state, searchSettings: action.payload, loading: true
      }

    case actions.ADD_KEYWORD:
      return {
        ...state,
        searchingResult: [
          action.payload,
          ...state.searchingResult
        ],
        scanNr: state.scanNr + 1 + 1, // 4 is the number of books to scan
        scannedNr: state.scannedNr,
        keywordScanned: state.keywordScanned + 1,
        loading: true
      }

    case actions.UPDATE_KEYWORD: {
      const sr = [...state.searchingResult];
      const kIndex = sr.findIndex(x => x.id === action.id);
      sr[kIndex] = {
        ...sr[kIndex],
        ...action.payload
      }

      return {
        ...state, searchingResult: sr,
      }
    }

    case actions.GET_SUGGESTIONS_SUCCESS:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].suggestions = action.suggestions;
        return {
          ...state,
          searchingResult: sr,
          // scanNr: state.scanNr + action.suggestions.length
          scanNr: state.scanNr
        }
      }

    case actions.GET_SUGGESTIONS_ERROR:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].errors.suggestions.value = action.error;
        return {
          ...state,
          searchingResult: sr
        }
      }

    case actions.GET_AZ_SUCCESS:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].suggestions[action.suggIndex].az = action.az;

        return {
          ...state, searchingResult: sr
        }
      }

    case actions.GET_AZ_ERROR:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].errors.suggestions[action.suggIndex] = { az: action.error};

        return {
          ...state, searchingResult: sr
        }
      }

    case actions.GET_BOOK_SUCCESS:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].books[action.department] = {
          ...sr[kIndex].books[action.department],
          [action.page]: action.books
        };

        return {
          ...state, searchingResult: sr
        }
      }

    case actions.GET_BOOK_ERROR:
      {
        const sr = [...state.searchingResult];
        const kIndex = sr.findIndex(x => x.id == action.id);
        sr[kIndex].errors.books[action.department] = {
          ...sr[kIndex].errors.books[action.department],
          [action.page]: action.error
        };

        return {
          ...state, searchingResult: sr
        }
      }

    case actions.SEARCH_SUCCESS:
      return { ...state, searchingResult: [action.payload, ...state.searchingResult], loading: false }

    default:
      return state
  }
}
