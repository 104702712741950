const actions = {
  SET_STATE: 'search/SET_STATE',
  GET_LIST: 'search/GET_LIST',
  RESTORE: 'search/RESTORE',
  GET_ITEM: 'search/GET_ITEM',
  CREATE: 'search/CREATE',
  EDIT: 'search/EDIT',
  DELETE: 'search/DELETE',
  REPEAT: 'search/REPEAT'
}

export default actions
