import actions from './actions'

const initialState = {
  search_id: '',
  feedback: '',
  note: '',
  service_id: '',
  loading: false,
  feedbackResult:{
    feedback:{
      search_id:''
    }
  },
  feedbackList: {
    data: [],
    current_page: 1,
    per_page: 10,
    page: 1,
    total: 0,
    clients: 284
  },
  error: null,
}

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case actions.POST_FEEDBACK:
      return { ...state, ...action.payload }
    case actions.POST_FEEDBACK_SUCCESS:
      return { ...state, feedbackResult:action.payload }
    case actions.GET_FEEDBACKS_ALL:
      return { ...state, feedbackList:action.payload }
    case actions.GET_FEEDBACKS_ALL_SUCCESS:
      return { ...state, feedbackList:action.payload }
    default:
      return state
  }
}
