import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import scan from './scan/reducers'
import findAsins from './findAsins/reducers'
import app from './app/reducers'
import subscription from './subscription/reducers'
import searches from './searches/reducers'
import acos from './acos/reducers'
import fastPhrases from './fast-phrases/reducers'
import affiliate from './affiliate/reducers'
import books from './books/reducers'
import autopilots from './autopilots/reducers'
import booksPayments from './booksPayments/reducers'
import paymentsHistory from "./paymentsHistory/reducers";
import notifications from "./notifications/reducers";
import fetchFeedbacks from './feedbacks/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    app,
    scan,
    subscription,
    booksPayments,
    books,
    findAsins,
    searches,
    acos,
    fastPhrases,
    affiliate,
    autopilots,
    paymentsHistory,
    notifications,
    fetchFeedbacks,
  })

