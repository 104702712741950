export const items = [
    {
        name: "Fast Coins",
        pricesCodes: [
          {
            url: "searches/5 coins.png",
            id: "price_1P9R25Fj6Ixx0g7K5OC9tfiW",
            testId: "price_1P9R2UFj6Ixx0g7KvJdCvqd4",
            access: "month-year-lifetime"
          },
            {
                url: "searches/10 coins.png",
                id: "price_1KYuWvFj6Ixx0g7KYqqXNgAE",
                testId: "price_1KlBETFj6Ixx0g7Ky4aXBZ9O",
                access: "month-year-lifetime"
            },
            {
                url: "searches/50 coins.png",
                id: "price_1K1AbXFj6Ixx0g7KCIGdO1LP",
                testId: "price_1Kr1KjFj6Ixx0g7KYbr05Thn",
                access: "year-lifetime"
            },
            {
                url: "searches/100 coins.png",
                id: "price_1K1AdDFj6Ixx0g7KR5wxuDSC",
                testId: "price_1Kr1L5Fj6Ixx0g7K7SP5KwRQ",
                access: "year-lifetime"
            },
            {
                url: "searches/200 coins.png",
                id: "price_1KYub0Fj6Ixx0g7Ks2rPK68Y",
                testId: "price_1KlBAjFj6Ixx0g7KgCC6HH7V",
                access: "lifetime"
            },
            {
                url: "searches/500 coins.png",
                testId: "price_1KlB9xFj6Ixx0g7KtUFBSD5Q",
                id: "price_1KcPHrFj6Ixx0g7K2Hmv0vBt",
                access: "lifetime"
            },
            {
                url: "searches/1000 coins.png",
                id: "price_1KcPInFj6Ixx0g7KleZj7GRS",
                testId: "price_1KlB9XFj6Ixx0g7KDZri5WRF",
                access: "lifetime"
            },
        ]
    },
    {
        name: "Fast Keys",
        pricesCodes: [
            {
                url: "keys/1-FAST-KEY-SITO.png",
                id: "price_1KcPW9Fj6Ixx0g7KBIm4IEn8",
                testId: "price_1KlAT4Fj6Ixx0g7KmDqBYOfY",
                access: "month-year-lifetime",
                yearPrice: 10,
                lifeTimePrice: 7,
            },
            {
                url: "keys/3-FAST-KEY-SITO.png",
                id: "price_1KcPWWFj6Ixx0g7Kto6jgnVa",
                testId: "price_1KlAScFj6Ixx0g7Ks89TftPl",
                access: "month-year-lifetime",
                yearPrice: 21,
                lifeTimePrice: 19,
            },
            {
                url: "keys/7-FAST-KEY-SITO.png",
                id: "price_1KcPXCFj6Ixx0g7KWo6r9SZe",
                testId: "price_1KlAS4Fj6Ixx0g7KXKSLmzKW",
                access: "month-year-lifetime",
                yearPrice: 42,
                lifeTimePrice: 37,
            },
        ]
    },
    {
        name: "Other Services",
        pricesCodes: [
          {
            url: "searches/LogoFastbookads.png",
            id: "price_1KzzMnFj6Ixx0g7KbeTOQ1xf",
            testId: "price_1LDrKwFj6Ixx0g7K0kaxNvNv",
            access: "month-year-lifetime",
          },
          {
            url: "searches/LogoFastbookads.png",
            id: "price_1KxTaiFj6Ixx0g7KaLc9rXI4",
            testId: "price_1LDrLoFj6Ixx0g7K911g1q7y",
            access: "month-year-lifetime",
          },
          {
            url: "onboardingCall.png",
            id: "price_1KzzEZFj6Ixx0g7K5fzQSg88",
            testId: "price_1LDrMfFj6Ixx0g7KG5pSMfnO",
            access: "month-year-lifetime",
            yearPrice: 55,
            lifeTimePrice: 49,
          },
          {
            url: "book.png",
            id: "price_1OH0RxFj6Ixx0g7K07rbOPJS",
            testId: "price_1OH0QwFj6Ixx0g7KJywt3xuX",
            access: "year-lifetime",
            yearPrice: 270,
            lifeTimePrice: 248,
          },
          {
            url: "video.png",
            id: "price_1P6ExlFj6Ixx0g7KAl6Zu50K",
            testId: "price_1P6EujFj6Ixx0g7K5zfpCcCk",
            access: "year-lifetime",
            yearPrice: 230,
            lifeTimePrice: 200,
          },
          {
            url: "pro.png",
            id: "price_1P6ExWFj6Ixx0g7KfAhZA0X3",
            testId: "price_1P6EweFj6Ixx0g7KBMB7CGRH",
            access: "year-lifetime",
            yearPrice: 500,
            lifeTimePrice: 400,
          },
        ]
    },

]

export default { items }
