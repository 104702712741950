/* eslint-disable */
import {all, takeEvery, put, call, select, delay} from 'redux-saga/effects'
import apiClient from 'services/axios'
import axios from 'axios'
import actions from './actions'
import moment from 'moment';
import {filterSuggestions, filterSuggestionsAll, filterSuggestionsAZ, getResultDetails} from '../../libs/utils'
import {downloadFile} from '../../libs/csv'
import departments from '../../pages/scan/data/departments.json';
import {CREATE as createSearch} from '../searches/sagas'

const getAzSuggestions = async (randomLink,payload) => apiClient.post(randomLink, payload);
const getAzSuggestions2 = async (payload) => apiClient.post(`get-suggestions-az2`, payload);
const getSuggestions = async (randomLink, payload) => apiClient.post(randomLink, payload);
const getSuggestions2 = async (payload) => apiClient.post(`get-suggestions2`, payload);
const getBooks = async (randomLink, payload) => apiClient.post(randomLink, payload);

const executeR = async (payload) => axios.post(`https://www.fastbooksoftware.com/R/run.php`, payload);


const getScan = state => state.scan;
const getApp = state => state.app;

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

export function* GET_AZ_LIST(payload) {
  const {suggestion, azSuggestion, id, market, keyword, suggIndex, repeated = false, serviceId = null} = payload
  const dprt = departments[1];
  try {
      Object.keys(azSuggestion).forEach(key => {
        azSuggestion[key] = filterSuggestionsAZ({newKeywordsList: azSuggestion[key], keyword})
        azSuggestion[key] = [...new Map(azSuggestion[key].map(item => [item, item])).values()]
        if (azSuggestion[key].length == 0) {
          delete azSuggestion[key];
        }
      })
      yield put({
        type: 'scan/GET_AZ_SUCCESS',
        az: azSuggestion,
        id,
        suggIndex
      });
    } catch (error) {
    console.log(error);
  }
}

export function* GET_SUGGESTIONS(payload) {

  const {id, market, keyword, repeated = false, serviceId = null} = payload
  try {
    // const url = 'https://dev1.app.fastbookads.com/api/get-suggestions-full';
    const url = 'https://api.app.fastbookads.com/api/get-suggestions-full';


    let {data: suggestions, status} = yield call(getSuggestions, url, {keyword, market, serviceId});
    if (status == 200) {
      suggestions = suggestions[0].suggestions.map(x => {
        return {
          value: x.keyword,
          az: x.suggestions
        }
      });
      suggestions = filterSuggestionsAll(suggestions, keyword);

      yield put({
        type: actions.GET_SUGGESTIONS_SUCCESS,
        id,
        suggestions
      });
      for (const suggIndex in suggestions) {
        yield put({
          type: actions.GET_AZ_LIST,
          id,
          suggIndex: Number(suggIndex),
          suggestion: suggestions[suggIndex].value,
          azSuggestion: suggestions[suggIndex].az,
          market,
          keyword,
          serviceId,
        });
      }
      yield delay(250);
      yield put({type: actions.REQ_LOADED});
    } else {
      console.log('error sug1')
      yield delay(250);
      yield put({type: actions.REQ_LOADED});
    }
  } catch (error) {
    console.log('error sug2');
  }
}


// export function* GET_BOOKS({keyword, market, id, serviceId}) {
//   try {
//     const url = 'https://www.dev1.app.fastbookads.com/api/get-books';
//
//     const {data: result, status} = yield call(getBooks, url, {keyword, market, serviceId});
//
//     if (status == 200) {
//       let booksKeywords = [];
//       result.products.forEach(b => {
//         if (!b) {
//           return false;
//         }
//         if (!b.title.includes("Sponsored")) {
//           if (b.title.indexOf(':') > 0) {
//             b.title = b.title.substr(0, b.title.indexOf(":"));
//           }
//           if (b.title.indexOf('.') > 0) {
//             b.title = b.title.substr(0, b.title.indexOf("."));
//           }
//           b.title = b.title.replace(/,/g, ' ').replace(/  /g, ' ');
//           booksKeywords.push({
//             title: b.title.toLowerCase(),
//             type: 'Book Title',
//             author: b.author
//           })
//         }
//         if (b.author != '') {
//           if (b.author.indexOf(',') > 0) {
//             b.author = b.author.split(',');
//             booksKeywords.push({
//               title: b.author[0],
//               type: 'Author Name',
//             })
//             booksKeywords.push({
//               title: b.author[1],
//               type: 'Author Name'
//             })
//           } else {
//             booksKeywords.push({
//               title: b.author,
//               type: 'Author Name'
//             })
//           }
//         }
//       });
//
//       yield put({
//         type: actions.GET_BOOK_SUCCESS,
//         books: booksKeywords,
//         id,
//         department,
//         page
//       });
//     } else {
//       yield put({
//         type: actions.GET_BOOK_ERROR,
//         error: JSON.stringify(result),
//         id,
//         department,
//         page
//       });
//     }
//     yield put({type: actions.REQ_LOADED});
//   } catch (error) {
//     yield put({type: actions.REQ_LOADED});
//     yield put({
//       type: actions.GET_BOOK_ERROR,
//       error: JSON.stringify(error),
//       id,
//       department,
//       page
//     });
//     console.log(error);
//   }
// }
export function* GET_BOOKS({keyword, market, id, serviceId}) {
  try {
    // const url = 'https://dev1.app.fastbookads.com/api/get-books';
    const url = 'https://api.app.fastbookads.com/api/get-books-full';
    const {data: results, status} = yield call(getBooks, url, {keyword, market, serviceId});

    if (status == 200) {
      for (const result of results) {
        let booksKeywords = [];

        if (!result.products || !Array.isArray(result.products)) {
          continue; // Пропустить, если products не существует или не является массивом
        }

        result.products.forEach(b => {
          if (!b) {
            return; // Пропустить, если b не определен
          }
          if (!b.title.includes("Sponsored")) {
            if (b.title.indexOf(':') > 0) {
              b.title = b.title.substr(0, b.title.indexOf(":"));
            }
            if (b.title.indexOf('.') > 0) {
              b.title = b.title.substr(0, b.title.indexOf("."));
            }
            b.title = b.title.replace(/,/g, ' ').replace(/  /g, ' ').trim();

            booksKeywords.push({
              title: b.title.toLowerCase(),
              type: 'Book Title',
              author: b.author || ''
            });
          }
          if (b.author) {
            if (b.author.indexOf(',') > 0) {
              const authors = b.author.split(',');
              if (authors.length > 0) {
                booksKeywords.push({
                  title: authors[0].trim(),
                  type: 'Author Name'
                });
              }
              if (authors.length > 1) {
                booksKeywords.push({
                  title: authors[1].trim(),
                  type: 'Author Name'
                });
              }
            } else {
              booksKeywords.push({
                title: b.author.trim(),
                type: 'Author Name'
              });
            }
          }
        });

        // Отправка действия для каждого элемента results
        yield put({
          type: actions.GET_BOOK_SUCCESS,
          books: booksKeywords,
          id,
          department: result.category || '',
          page: result.page || 1
        });
      }
    } else {
      yield put({
        type: actions.GET_BOOK_ERROR,
        error: JSON.stringify(results),
        id,
        department,
        page
      });
    }

    yield put({type: actions.REQ_LOADED});
  } catch (error) {
    yield put({type: actions.REQ_LOADED});
    yield put({
      type: actions.GET_BOOK_ERROR,
      error: JSON.stringify(error),
      id,
      department,
      page
    });
    console.log(error);
  }
}


export function* SEARCH_KEYWORD({payload}) {
  const dprt = departments[2];
  const {market, keywords, phrases, isCookBook, removed} = payload;
  const {app} = yield select(s => s);
  const departmentsList = [departments[1], departments[2]];
  if (app.service == '6') departmentsList.push(departments[3]);

  try {
    const createdSearch = yield call(createSearch, {
      payload: {
        ...payload,
        departments: departmentsList.map(x => x.value),
        inputs: {market: market.value, keywords, isCookBook, phrases, removed}
      }
    });

    if (!createdSearch) {
      yield put({
        type: actions.SET_STATE,
        payload: {loading: false}
      });
      return false
    }
    ;

    yield put({
      type: actions.SET_STATE,
      payload: {searchSettings: {...payload, id: createdSearch.searchId}}
    });

    for (const keywordIdx in keywords) {

      const keyword = keywords[keywordIdx];

      const {searchingResult} = yield select(store => store.scan);
      const searchingId = searchingResult.length + 1;

      yield put({
        type: actions.ADD_KEYWORD,
        payload: {
          id: searchingId,
          keyword,
          department: dprt,
          suggestions: [],
          books: {},
          errors: {
            suggestions: {
              az: {}
            },
            books: {},
          },
          date: Number(moment().format('x')),
          loading: true
        }
      });

      // for (const department of [departments[1], departments[2], departments[3]]) {
      //   console.log(department.value)
      //       for (const page of [1, 2]) {
              yield put({
                type: actions.GET_BOOKS,
                id: searchingId,
                keyword,
                market,
                serviceId: createdSearch.serviceId
              });
            // }
            yield delay(150);
          // }

      yield put({
        type: actions.GET_SUGGESTIONS,
        id: searchingId,
        keyword,
        market,
        serviceId:createdSearch.serviceId,
      });

      // yield put({
      //   type: actions.GET_BOOKS,
      //   id: searchingId,
      //   keyword,
      //   market,
      //   serviceId: createdSearch.serviceId
      // });
      //
      // if (createdSearch.serviceId == 6){
      //   for (const department of [departments[1], departments[2], departments[3]]) {
      //     for (const page of [1, 2]) {
      //       yield put({
      //         type: actions.GET_BOOKS,
      //         id: searchingId,
      //         keyword,
      //         market,
      //         department: department.value,
      //         page,
      //         serviceId: createdSearch.serviceId
      //       });
      //     }
      //     yield delay(150);
      //   }
      // } else {
      //   for (const department of [departments[1], departments[2]]) {
      //     for (const page of [1, 2]) {
      //       yield put({
      //         type: actions.GET_BOOKS,
      //         id: searchingId,
      //         keyword,
      //         market,
      //         department: department.value,
      //         page
      //       });
      //     }
      //     yield delay(150);
      //   }
      // }


    }
  } catch (error) {
    yield put({
      type: actions.SET_STATE,
      payload: {loading: false}
    });
  }

}

function* EXECUTE_R({gkp, market, selectedService}) {
  const scanState = yield select(getScan);
  const {devMode} = yield select(getApp);

  const {
    searchingResult,
    searchSettings: {
      id: searchId
    }
  } = scanState;

  let {amazonFile, kwPrincFile} = getResultDetails(searchingResult);

  const formData = new FormData();
  formData.append("ams_keyword", amazonFile);

  if (gkp) {
    formData.append("keyword_stats", gkp);
    devMode && downloadFile(gkp);
  }

  formData.append("kw_princ", kwPrincFile);

  devMode && downloadFile(amazonFile);
  devMode && downloadFile(kwPrincFile);

  try {
    yield put({type: actions.SET_STATE, payload: {rIsRunning: true}});
    const {data, status} = yield call(executeR, formData);

    if (selectedService.id == 6) {
      let text = '';
      let text2 = '';
      switch (market.value) {
        case 'DE':
          text = 'hörbuch';
          text2 = 'hörbuch audible';
          break;
        case 'FR':
          text = 'livre audio';
          text2 = 'livre audible';
          break;
        case 'IT':
          text = 'audiobook';
          text2 = 'audible libro';
          break;
        case 'ES':
          text = 'audiolibros'
          text2 = 'audible libro';
          break;

        default :
          text = 'audiobook';
          text2 = 'audible book';
      }

      searchingResult.forEach((item, index) => {
        data.push({
          "PR Title": item.keyword + ' ' + text,
          "Score": 9.9,
          "Score.1": 9.9,
          "Score.2": 9.9,
          "Removed": "",
          "Source": "PR Title"
        });

        data.push({
          "PR Title": item.keyword + ' ' + text2,
          "Score": 9.9,
          "Score.1": 9.9,
          "Score.2": 9.9,
          "Removed": "",
          "Source": "PR Title"
        });

        data.push({
          "PR Title": item.keyword + ' audible',
          "Score": 9.9,
          "Score.1": 9.9,
          "Score.2": 9.9,
          "Removed": "",
          "Source": "PR Title"
        });

        data.push({
          "PR Title": item.keyword + ' audio',
          "Score": 9.9,
          "Score.1": 9.9,
          "Score.2": 9.9,
          "Removed": "",
          "Source": "PR Title"
        });
      })
    }

    data.sort((a, b) => b.Score - a.Score);
    switch (status) {
      case 200:
        yield put({
          type: 'search/EDIT',
          payload: {
            result: searchingResult,
            rResult: data,
            id: searchId
          }
        });
        yield put({
          type: actions.SET_STATE,
          payload: {rResult: data}
        });
        // window.open('https://www.fastbooksoftware.com/R/out_keysim.xlsx', '_blank');
        break;
      default:
        console.log(data.message);
    }
  } catch (error) {
    console.log("Adding file error:  " + error);
  }
  yield put({type: actions.SET_STATE, payload: {rIsRunning: false}});
}

function* REQ_LOADED() {
  const {
    scannedNr,
    scanNr,
  } = yield select(getScan);

  const loading = scannedNr + 1 < scanNr;

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading,
      runR: !loading,
      scannedNr: scannedNr + 1,
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEARCH_KEYWORD, SEARCH_KEYWORD),
    takeEvery(actions.GET_SUGGESTIONS, GET_SUGGESTIONS),
    takeEvery(actions.GET_AZ_LIST, GET_AZ_LIST),
    takeEvery(actions.GET_BOOKS, GET_BOOKS),
    takeEvery(actions.EXECUTE_R, EXECUTE_R),
    takeEvery(actions.REQ_LOADED, REQ_LOADED),
  ])
}
