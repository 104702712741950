import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {store as reduxStore} from 'index'
import {Button, Input, Select} from 'antd';
import {QuestionCircleOutlined, CaretDownOutlined} from '@ant-design/icons';
import style from './style.module.scss'

const {TextArea} = Input;

const HelpSection = () => {
  const {user} = useSelector(state => state);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(false);
  const [showTypes, setShowTypes] = useState(false);
  const [option, setOption] = useState(false);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [showInputs, setShowInputs] = useState(true);
  const { Option } = Select;
  const [errorMessage, setErrorMessage] = useState("");


  const sendMessage = () => {
    if (!message || !subject || !option) {
      setErrorMessage("All fields are required");
      // AntMessage.error("All fields are required");
      return;
    }
    reduxStore.dispatch({
      type: 'app/SEND_HELP_MESSAGE',
      payload: {message, subject, option, type}
    });
    setShowInputs(false);
    setShowTypes(false);
    setType(false);
    setMessage('');
    setTimeout(() => {
      setShowModal(false)
    }, 3000);
  }

  if (!showModal && !showTypes) {
    return (
      <div className={style.fixed}>
        <Button
          type="primary"
          shape="round"
          size="large"
          icon={<QuestionCircleOutlined />}
          // onClick={() => setShowModal(true)}
          onClick={() => setShowTypes(true)}
        > Help
        </Button>
      </div>
    )
  }

  if (!showModal && showTypes) {
    return (
      <div className={style.fixed}>
        <div className="mb-4">
          <Button
            type="primary"
            shape="round"
            size="large"
            // icon={<QuestionCircleOutlined />}
            onClick={() => {
              setShowModal(true);
              setType('Fast Software');
            }}
          > Fast Software
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              setShowModal(true);
              setType('Ads Robot');
            }}
          > Ads Robot
          </Button>
        </div>
      </div>
    )
  }

  const messageAppear = () => {
    if (showInputs) {
      return (
        <div>
          Hi {user.firstName}, how can we help you today? 😊
          <Select
            className="mt-3"
            placeholder="Select an option"
            size="large"
            onChange={(value) => setOption(value)}
          >
            <Option value="Question">Question</Option>
            <Option value="Suggestion">Suggestion</Option>
            <Option value="Report a problem">Report a problem</Option>
          </Select>
          <Input
            className="mt-3"
            placeholder="Add a subject"
            onChange={(e) => setSubject(e.target.value)}
            maxLength={100}
            size="large"
          />
          <TextArea
            rows={5}
            className="mt-3"
            placeholder="Write the message..."
            maxLength={600}
            onChange={(e) => setMessage(e.target.value)}
            size="large"
          />
          <div className="text-right mt-3">
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <Button
              type="primary"
              onClick={sendMessage}
            > Send message
            </Button>
          </div>

        </div>
      )
    }

    return (
      <div className={`card-body w-full ${style.text}`}>
        Thank you for the message! It was sent successfully and very soon you will get the answer
        via email!
      </div>
    )

  }

  return (
    <div className={style.wrapper}>
      <div className={`card ${style.card}`}>
        <div style={{backgroundColor: '#fea726', padding: '10px 0px', borderRadius: "10px 10px 0 0"}}>
          <div className="text-center text-white">
            {type ?? "Fast Book Ads"} Support
            <Button
              style={{position: 'absolute', right: '0', top: "3px"}}
              size="large"
              type="link"
              icon={<CaretDownOutlined />}
              onClick={() => {
                setShowModal(false);
                setShowTypes(false);
                setType(false);
                setErrorMessage(false);
              }}

            />
          </div>
        </div>
        <div className="card-body">
          {messageAppear()}
        </div>
      </div>
    </div>
  )
}

export default HelpSection
