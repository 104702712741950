import { all, takeEvery, takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import store from 'store'
import axios from 'axios'
import { history } from 'index'
import apiClient from 'services/axios'
import actions from './actions'
import markets from '../../pages/scan/data/markets.json'

const getList = async (payload) => apiClient.get(`autopilots`, { params: payload })
const getColoredBooks = async (payload) => apiClient.get(`getColoredBooks`, { params: payload })
const createColoredBook = async (payload) => apiClient.post(`createColoredBook`, { params: payload })
const changeKdpName = async (payload) => apiClient.post(`changeKdpName`, { params: payload })
const deleteColoredBook =  async payload => apiClient.delete(`deleteColoredBook/${payload}`)
const getHistory = async (payload) => apiClient.get(`autopilotsHistory`, { params: payload })
const getLanguageBook = async (payload) => apiClient.post(`booksGet`, payload)
const getItem = async (id) => apiClient.get(`books/${id}`)
const getBookChart = async (id) => apiClient.get(`getBookChart/${id}`)
const createBook = async payload => apiClient.post(`books`, payload)
const editBook = async payload => apiClient.put(`books/${payload.id}`, payload)
const deleteBook = async payload => apiClient.delete(`books/${payload}`)

export function* GET_ITEM({ id }) {
  const { bookItem } = yield select(state => state.books)
  const bookId = id || bookItem.id;
  try {
    const { data, status } = yield call(getItem, bookId)
    switch (status) {
      case 200:
        yield put({
          type: 'book/SET_STATE', payload: {
            bookItem: {
              ...data,
              inputs: JSON.parse(data.inputs),
              departments: JSON.parse(data.departments),
              keywords: JSON.parse(data.keywords),
              result: JSON.parse(data.result),
              rResult: JSON.parse(data.rResult),
            }
          }
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_CHART({ bookId }) { // Change 'id' to 'bookId'
  try {
    console.log(bookId, 'get chart') // Change 'id' to 'bookId'
    bookId = 3;
    const { data, status } = yield call(getBookChart, bookId) // Change 'id' to 'bookId'
    switch (status) {
      case 200:
        yield put({
          type: 'book/GET_CHART', payload: data
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* GET_LIST({ payload }) {
  try {
    const { data, status } = yield call(getList, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'autopilot/SET_STATE',
          payload: { ...data, autopilotList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_COLORED_BOOKS({ payload }) {
  try {
    const { data, status } = yield call(getColoredBooks, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'autopilot/SET_STATE',
          payload: { ...data, coloredBooks: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE_COLORED_BOOK({ payload }) {
  try {
    const { data, status } = yield call(createColoredBook, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'autopilot/GET_COLORED_BOOKS',
          payload: {},
        })
        ntf.success({
          message: 'Colored Book Added Successfully',
          description: '',
        });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CHANGE_NAME_KDP({ payload }) {
  try {
    console.log(payload)

    const { data, status } = yield call(changeKdpName, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'autopilot/GET_LIST',
          payload: {},
        })
        ntf.success({
          message: 'Account Name Changed Successfully',
          description: '',
        });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE_COLORED_BOOK({ payload }) {
  try {
    const { data, status } = yield call(deleteColoredBook, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The book is deleted Succesfully',
          description: '',
        })
        yield put({
          type: 'autopilot/GET_COLORED_BOOKS',
          payload: {},
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* GET_HISTORY({ payload }) {
  try {
    const { data, status } = yield call(getHistory, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'autopilot/SET_STATE',
          payload: { ...data, autopilotHistory: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE({ payload }) {
  try {
    // const { data: ip = '' } = yield call(getIp);

    const { app } = yield select(s => s);

    const { data, status } = yield call(createBook, {
      ...payload,
    });

    switch (status) {
      case 201:
        yield put({
          type: 'book/SET_STATE',
          payload: { ...data, bookList: data },
        })
        ntf.success({
          message: 'Book added Successfully',
          description: '',
        });
        // getList();
        return false;
      case 422:
        ntf.error({
          message: data,
          description: '',
        })
        return false;
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    ntf.error({
      message: 'There was a problem while add book, please report it!',
      description: '',
    })
    return false
  }
}

export function* GET_LANGUAGE({ payload }) {
  try {
    const { app } = yield select(s => s);
    const { data, status } = yield call(getLanguageBook, {
      ...payload,
    });
    switch (status) {
      case 200:
        yield put({
          type: 'book/SET_STATE',
          payload: { bookLang: data}
        });
        break
      default:
        ntf.error({
          message: data,
          description: '',
        })
        // console.log('Error: ', data.message)
    }
  } catch (error) {
    ntf.error({
      message: 'Can\'nt find ASIN!',
      description: '',
    })
    return false
  }
}

export function* EDIT({ payload }) {
  const { user } = yield select(s => s);
  try {
    const { data, status } = yield call(editBook, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'user/SET_STATE',
          payload: { points: user.points - 1 }
        });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE({ payload }) {
  try {
    const { data, status } = yield call(deleteBook, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The book is deleted Succesfully',
          description: '',
        })
        yield history.push(`/dashboard/my-books`)
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_COLORED_BOOKS, GET_COLORED_BOOKS),
    takeEvery(actions.CREATE_COLORED_BOOK, CREATE_COLORED_BOOK),
    takeEvery(actions.DELETE_COLORED_BOOK, DELETE_COLORED_BOOK),
    takeEvery(actions.GET_ITEM, GET_ITEM),
    takeEvery(actions.GET_LANGUAGE, GET_LANGUAGE),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    takeEvery(actions.DELETE, DELETE),
    takeLatest(actions.GET_CHART, GET_CHART),
    takeLatest(actions.GET_HISTORY, GET_HISTORY),
    takeLatest(actions.CHANGE_NAME_KDP, CHANGE_NAME_KDP),
  ])
}

