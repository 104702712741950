const actions = {
    SET_STATE: 'affiliate/SET_STATE',
    GET_DASHBOARD_DATA: 'affiliate/GET_DASHBOARD_DATA',
    GET_REFFERED_CUSTOMERS: 'affiliate/GET_REFFERED_CUSTOMERS',
    GET_RANKING: 'affiliate/GET_RANKING',
    AFFILIATE_PAYMENT: 'affiliate/AFFILIATE_PAYMENT',
    GET_FINANCE: 'affiliate/GET_FINANCE',
    GET_EARNINGS: 'affiliate/GET_EARNINGS',
    REFER_FRIEND: 'affiliate/REFER_FRIEND'
}

export default actions