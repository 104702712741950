import { all, takeEvery, takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import store from 'store'
import axios from 'axios'
import { history } from 'index'
import apiClient from 'services/axios'
import actions from './actions'
import markets from '../../pages/scan/data/markets.json'

const getHistory = async (payload) => apiClient.get(`paymentsHistory`, { params: payload })

export function* GET_HISTORY({ payload }) {
  try {
    const { data, status } = yield call(getHistory, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'paymentsHistory/SET_STATE',
          payload: { ...data, paymentsHistory: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_HISTORY, GET_HISTORY),
  ])
}

