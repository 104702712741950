const actions = {
  SET_STATE: 'app/SET_STATE',
  GET_DATA: 'app/GET_DATA',
  CHANGE_SERVICE: 'app/CHANGE_SERVICE',
  CLEAR_ALL_TOKENS: 'app/CLEAR_ALL_TOKENS',
  SEND_HELP_MESSAGE: 'app/SEND_HELP_MESSAGE',
  GET_LOGS: 'app/GET_LOGS',
  GET_REPORT: 'app/GET_REPORT'
}

export default actions
