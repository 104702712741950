import { all, takeEvery, takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import { history } from 'index'
import apiClient from 'services/axios'
import actions from './actions'

const getList = async (payload) => apiClient.get(`notifications`, { params: payload })
const createNotification = async payload => apiClient.post(`notifications`, payload)
const deleteNotification = async payload => apiClient.delete(`notifications/${payload}`, payload)

export function* GET_LIST({ payload }) {
  try {
    const { data, status } = yield call(getList, payload)
    console.log(data)
    switch (status) {
      case 200:
        yield put({
          type: 'notifications/SET_STATE',
          payload: { ...data, notificationsList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE_NOTIFICATION({ payload }) {
  try {
    const { data, status } = yield call(deleteNotification, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The notification was deleted Succesfully',
          description: '',
        })
        yield put({
          type: 'notifications/GET_LIST',
          payload: {},
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE({ payload }) {
  try {
    // const { data: ip = '' } = yield call(getIp);

    const { app } = yield select(s => s);

    const { data, status } = yield call(createNotification, {
      ...payload,
    });

    switch (status) {
      case 200:
        yield put({
          type: 'notifications/SET_STATE',
          payload: { ...data, notifications: data },
        })
        ntf.success({
          message: 'Notification was sent successfully',
          description: '',
        });
        return false;
      case 422:
        ntf.error({
          message: data,
          description: '',
        })
        return false;
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    ntf.error({
      message: 'There was a problem!',
      description: '',
    })
    return false
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE_NOTIFICATION, DELETE_NOTIFICATION),
  ])
}
