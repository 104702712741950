/* eslint-disable */
import { all, takeEvery, put, call, select, delay } from 'redux-saga/effects'
import { notification } from 'antd'
import { notification as ntf } from 'antd'
import apiClient from 'services/axios'
import actions from './actions'
import { generateData } from './optimizeRresult'
import { CREATE as createSearch } from '../searches/sagas'

const getBooks = async (randomLink, payload) => apiClient.post(randomLink, payload);

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

export function* GET_ASINS({ keyword, market, department, page }) {
  try {
    const urls = [
      // 'https://www.dev1.app.fastbookads.com/api/get-books',
      'https://www.api.app.fastbookads.com/api/get-books',
    ];

// Function to get a random URL
    const getRandomURL = () => {
      const randomIndex = Math.floor(Math.random() * urls.length);
      return urls[randomIndex];
    };

// Example usage:
    const randomLink = getRandomURL();
    const { data: result, status } = yield call(getBooks, randomLink, { keyword, market, department, page });
    if (status == 200 && result) {
      const products = result.products.filter(x => x);
      yield put({
        type: 'findAsins/SEARCH_BOOKS_SUCCESS',
        data: {
          ...result,
          products,
          page,
          totalProducts: products.length
        }
      });
    }
    yield put({ type: 'findAsins/REQ_LOADED' });
  } catch (error) {
    console.log(error);
    yield put({ type: 'findAsins/REQ_LOADED' });
  }
}

export function* SEARCH_BOOKS({ payload }) {
  const { departments, keywords, market, removed } = payload;

  try {
    const createdSearch = yield call(createSearch, { payload });

    if (!createdSearch) {
      yield put({
        type: 'findAsins/SET_STATE',
        payload: { loading: false }
      });
      return false
    }

    const resultEstimation = keywords.length * departments.length;
    // let pages = [1, 2, 3];
    let pages = [1, 2];
    yield put({
      type: 'findAsins/SET_STATE',
      payload: {
        scanNr: resultEstimation * pages.length,
        scannedNr: 0,
        searchSettings: { ...payload, id: createdSearch.searchId }
      }
    });

    for (const page of pages) {
      yield delay(200);
      for (const keyword of keywords) {
        yield delay(200);
        for (const department of departments) {
          yield put({
            type: 'findAsins/GET_ASINS',
            keyword,
            department,
            page,
            market,
            removed
          });
        }
      }
    }

  }
  catch (error) {
    console.log('error', error);
  }
}

function* REQ_LOADED() {
  const {
    searchSettings,
    searchingResult,
    scannedNr,
    scanNr,
  } = yield select(state => state.findAsins);

  const loading = scannedNr + 1 < scanNr;

  yield put({
    type: 'findAsins/SET_STATE',
    payload: {
      loading,
      scannedNr: scannedNr + 1,
    }
  });

  if (!loading) {
    const { rSheet, asins, sortedSearches  } = generateData(searchingResult, searchSettings); // convert to exel data
    yield put({
      type: 'findAsins/SET_STATE',
      payload: {
        rIsRunning: false,
        resultSheet: rSheet,
        asins,
        searchingResult: sortedSearches,
      }
    });

    yield put({
      type: 'search/EDIT',
      payload: {
        result: sortedSearches,
        id: searchSettings.id,
        removed:searchSettings?.removed || []
      }
    });

    notification.success({
      message: 'The Searching has been finished',
      description: 'You can Download the file or copy them',
      placement: 'bottomRight'
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEARCH_BOOKS, SEARCH_BOOKS),
    takeEvery(actions.GET_ASINS, GET_ASINS),
    takeEvery(actions.REQ_LOADED, REQ_LOADED),
  ])
}

