
const actions = {
  GET_LIST: 'notifications/GET_LIST',
  CREATE: 'notifications/CREATE',
  DELETE_NOTIFICATION: 'notifications/DELETE_NOTIFICATION',
  SET_STATE: 'notifications/SET_STATE',
}

export default actions

