import actions from './actions'

const initialState = {
  settings: {
    id: '',
    market: null,
    phrases: [],
    isCookBook: false
  },
  searchId: '',
  result: null,
  loading: false
}

export default function fastPhrasesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_STATE:
      return { ...initialState, ...action.payload }

    case actions.PRODUCE_FAST_PHRASES:
      return {
        ...state, settings: action.payload, loading: true
      }

    case actions.PRODUCE_FAST_PHRASES_SUCCESS:
      return {
        ...state, result: action.data
      }
    default:
      return state
  }
}
