import actions from './actions'

const initialState = {
    dashboardData: {},
    refferedCustomers: {},
    ranking: {},
    earnings: {},
    finance: {}
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
      case actions.SET_STATE:
        return { ...state, ...action.data}

      default:
        return state
    }
  }