import { all, put, call, takeEvery } from 'redux-saga/effects'
import apiClient from 'services/axios'
import { notification as ntf } from 'antd'
import actions from './actions'

const getDashboardData = async () => apiClient.get('affiliate-dashboard');
const getRefferedCustomers = async () => apiClient.get('referred-customers')
const getRanking = async () => apiClient.get('affiliate-rank')
const affiliatePayment = async () => apiClient.post('affiliate-payment')
const getAffiliateFinance = async () => apiClient.get('affiliate-finance')
const referFriend = async (payload) => apiClient.post('affiliate-email', payload)
const getEarnings = async () => apiClient.get('affiliate-payments')

export function* GET_DASHBOARD_DATA() {
  try {
    const { data, status } = yield call(getDashboardData)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        data: {
          dashboardData: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* REFER_FRIEND({ payload }) {
  try {
    const { status } = yield call(referFriend, payload)
    if (status == 200) {
      ntf.success({ message: "The Email has been send successfully!" })
    } else {
      ntf.error({ message: 'We were not able to send an email to his account!' })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* GET_REFFERED_CUSTOMERS() {
  try {
    const { data, status } = yield call(getRefferedCustomers)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        data: {
          refferedCustomers: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* GET_RANKING() {
  try {
    const { data, status } = yield call(getRanking)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        data: {
          ranking: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* AFFILIATE_PAYMENT() {
  try {
    const { data, status } = yield call(affiliatePayment);
    if (status == 200) {
      ntf.success({ message: "Succcessfully data sent!" })
    } else {
      ntf.error({ message: 'We were not able to sent data!' })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* GET_EARNINGS() {
  try {
    const { data, status } = yield call(getEarnings)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        data: {
          earnings: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export function* GET_FINANCE() {
  try {
    const { data, status } = yield call(getAffiliateFinance)
    if (status == 200) {
      yield put({
        type: actions.SET_STATE,
        data: {
          finance: data
        }
      })
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DASHBOARD_DATA, GET_DASHBOARD_DATA),
    takeEvery(actions.GET_REFFERED_CUSTOMERS, GET_REFFERED_CUSTOMERS),
    takeEvery(actions.GET_RANKING, GET_RANKING),
    takeEvery(actions.GET_FINANCE, GET_FINANCE),
    takeEvery(actions.GET_EARNINGS, GET_EARNINGS),
    takeEvery(actions.REFER_FRIEND, REFER_FRIEND)
  ])
}