import actions from './actions'

const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  role: '',
  email: '',
  avatar: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false,
  loading: false,
  userList: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
  },
  userItem: {},
  userSearchesList: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
    clients: 284
  },
  token: '',
  isSubscribed: false,
  payments: [],
  subscription: [],
  roles: [],
  roleId: null,
  ip: '',
  points: 0,
  resetPassStatus: null,
  isTokenValid: true,
  resetingValues: null,
  referencePriceId: 0,
  paidVideo: false,
  paidVideo3: false,
  newUser: true,
  bank_account: null,
  hasLifetimePlan: false,
  query: [],
  affiliateBalance: 0,
  authLogList: [],
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.GET_LIST_SUCCESS:
      return { ...state, userList: action.payload }
    case actions.GET_ITEM_SUCCESS:
      return { ...state, userItem: action.payload }
    case actions.CLEAR_STATE:
      localStorage.removeItem('accessToken');
      return { ...state, ...initialState }
    case actions.AUTH_LOG_LIST:
      return { ...state, authLogList: action.payload }
    default:
      return state
  }
}
