import actions from './actions'

const initialState = {
  loading: false,
  subscriptionList: [],
  subscriptionItem: {},
  prices: [],
  products: [],
  plans: [],
  paymentsList: {
    data: [],
    current_page: 1,
    per_page: 10,
    total: 0,
  },
  intent: null,
  selectedPriceId: null,
  coupon: null,
  shopPrices: []
}

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.GET_LIST_SUCCESS:
      return { ...state, subscriptionList: action.payload }
    case actions.GET_ITEM_SUCCESS:
      return { ...state, subscriptionItem: action.payload }
    case actions.CLEAR_STATE:
      return { ...state, ...initialState }
    default:
      return state
  }
}
