import { all, takeEvery, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import store from 'store'
import axios from 'axios'
import { history } from 'index'
import apiClient from 'services/axios'
import actions from './actions'
import markets from '../../pages/scan/data/markets.json'

const getList = async (payload) => apiClient.get(`searches`, { params: payload })
const getItem = async (id) => apiClient.get(`searches/${id}`)
const createSearch = async payload => apiClient.post(`searches`, payload)
const editSearch = async payload => apiClient.put(`searches/${payload.id}`, payload)
// const deleteSearch = async payload => apiClient.delete(`searches/${payload.id}`)
const deleteSearch = async payload => apiClient.delete(`searches-hide/${payload}`)
const restoreSearch = async payload => apiClient.get(`searches-restore/${payload}`)
const getIp = async () => axios.get('https://api.ipify.org/');

export function* GET_ITEM({ id }) {
  const { searchItem } = yield select(state => state.searches)
  const searchId = id || searchItem.id;
  try {
    const { data, status } = yield call(getItem, searchId)
    switch (status) {
      case 200:
        yield put({
          type: 'search/SET_STATE', payload: {
            searchItem: {
              ...data,
              inputs: JSON.parse(data.inputs),
              departments: JSON.parse(data.departments),
              keywords: JSON.parse(data.keywords),
              result: JSON.parse(data.result),
              rResult: JSON.parse(data.rResult),
            }
          }
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_LIST({ payload }) {
  try {
    const { data, status } = yield call(getList, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'search/SET_STATE',
          payload: { searchList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE({ payload }) {
  try {
    // const { data: ip = '' } = yield call(getIp);

    const { app } = yield select(s => s);

    const { data, status } = yield call(createSearch, {
      ...payload,
      serviceId: app.service,
      ip: ''
    });

    switch (status) {
      case 201:
        return { searchId: data.id, serviceId: data.service };
      case 422:
        ntf.error({
          message: data,
          description: '',
        })
        return false;
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    ntf.error({
      message: 'There was a problem while searching, please report it!',
      description: '',
    })
    return false
  }
}

export function* EDIT({ payload }) {
  const { user } = yield select(s => s);
  try {
    const { data, status } = yield call(editSearch, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'user/SET_STATE',
          payload: { points: user.points - 1 }
        });
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE({ payload }) {
  try {
    const { data, status } = yield call(deleteSearch, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The search is deleted Succesfully',
          description: '',
        })
        yield history.push(`/dashboard/my-searches`)
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* RESTORE({ payload }) {
  try {
    console.log(payload)
    const { data, status } = yield call(restoreSearch, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The search is restored Succesfully',
          description: '',
        })
        yield history.push(`/dashboard/my-searches`)
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* REPEAT({ payload, notParsed }) {
  const search = { ...payload };
  if (notParsed) {
    search.departments = JSON.parse(search.departments);
    search.keywords = JSON.parse(search.keywords);
    search.inputs = JSON.parse(search.inputs);
  }

  if (search.service == 2) {
    yield put({ type: 'findAsins/CLEAR_STATE' });
    yield put({
      type: 'findAsins/SET_STATE',
      payload: {
        searchSettings: {
          keywords: search.keywords,
          market: markets.find(x => x.value == search.market),
          departments: search.departments,
          removed: search?.inputs?.removed ?? [],
        },
      }
    })
  } else if (search.service == 4) {
    yield put({ type: 'acos/CLEAR_STATE' });
    yield put({
      type: 'acos/SET_STATE',
      payload: {
        settings: search.inputs,
      }
    })
  } else if (search.service == 5) {
    yield put({ type: 'fastPhrases/CLEAR_STATE' });
    yield put({
      type: 'fastPhrases/SET_STATE',
      payload: {
        settings: search.inputs,
      }
    })
  } else {
    yield put({ type: 'scan/CLEAR_STATE' })
    yield put({
      type: 'scan/SET_STATE',
      payload: {
        searchSettings: {
          keywords: search.keywords,
          market: markets.find(x => x.value == search.market),
          phrases: search.inputs.phrases,
          removed: search.inputs.removed,
          isCookBook: search.inputs.isCookBook,
        },
      }
    })
  }

  yield put({ type: 'app/SET_STATE', payload: { service: search.service } });
  if (search.service < 4) {
    history.push(`/dashboard/${search.service == 2 ? 'fast-products' : 'scan'}`);
  } else if (search.service == 5) {
    history.push(`/dashboard/fast-phrases`);
  } else if (search.service == 6) {
    history.push(`/dashboard/scan`);
  } else {
    history.push(`/dashboard/acos`);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_ITEM, GET_ITEM),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.REPEAT, REPEAT),
    takeEvery(actions.RESTORE, RESTORE),
  ])
}
