
const actions = {
  SET_STATE: 'autopilot/SET_STATE',
  GET_CHART: 'autopilot/GET_CHART',
  GET_LIST: 'autopilot/GET_LIST',
  CHANGE_NAME_KDP: 'autopilot/CHANGE_NAME_KDP',
  GET_COLORED_BOOKS: 'autopilot/GET_COLORED_BOOKS',
  CREATE_COLORED_BOOK: 'autopilot/CREATE_COLORED_BOOK',
  DELETE_COLORED_BOOK: 'autopilot/DELETE_COLORED_BOOK',
  GET_HISTORY: 'autopilot/GET_HISTORY',
  GET_ITEM: 'autopilot/GET_ITEM',
  GET_LANGUAGE: 'autopilot/GET_LANGUAGE',
  CREATE: 'autopilot/CREATE',
  EDIT: 'autopilot/EDIT',
  DELETE: 'autopilot/DELETE',
  REPEAT: 'autopilot/REPEAT'
}

export default actions

