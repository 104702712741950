export const isDev = process.env.NODE_ENV === 'development' || window.location.host.includes('localhost') || window.location.host.includes('dev.');

export const STRIPE_KEY =
    isDev
        ? process.env.REACT_APP_STRIPE_KEY_TEST
        : process.env.REACT_APP_STRIPE_KEY

export const MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
]

export default { STRIPE_KEY, MONTHS }