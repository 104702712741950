import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import { CREATE as createSearch } from '../searches/sagas'

export function* PRODUCE_FAST_PHRASES({ payload }) {

  try {
    const createdSearch = yield call(createSearch, { payload: { inputs: payload } });
    if (!createdSearch.searchId) {
      yield put({
        type: 'fastPhrases/SET_STATE',
        payload: {
          loading: false,
        }
      });
      return false
    }
    yield put({
      type: 'fastPhrases/SET_STATE',
      payload: {
        loading: false,
        result: true,
        searchId: createdSearch.searchId, 
      }
    });

    yield put({
      type: 'search/EDIT',
      payload: {
        result: true,
        id: createdSearch.searchId
      }
    });
  }
  catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.PRODUCE_FAST_PHRASES, PRODUCE_FAST_PHRASES),
  ])
}