/* eslint-disable */

/**
 * Converts JSON object to stringified CSV
 *
 * @param {Array} data Array that contains the columns
 * @returns {String} Stringified CSV
 */
export function convertJsonToCsvString(data, fieldWrapper = '') {
  let stringified = "";
  const fields = Object.keys(data[0]);
  const rows = data.map(function (content) {
    return fields
      .map(function (field) {
        return fieldWrapper + ((content[field] || '').split('"').join('\\"') || "") + fieldWrapper;
      })
      .join(",");
  });

  stringified +=
    fields.map(function (field) {
      return fieldWrapper + (field.split('"').join('\\"') || "") + fieldWrapper;
    }) + "\n";
  stringified += rows.join("\n");

  return stringified;
}

/**
 * Converts stringified CSV to File
 *
 * @param {String} csvString Stringified CSV
 * @returns {File} CSV file that is created from the string
 */
export function convertCsvStringToFile(csvString, addBom = false, filename) {
  const BOM = '\uFEFF';
  const string = addBom ? BOM + csvString : csvString;
  const file = new File([string], filename || "data.csv", {
    type: "text/csv;charset=utf-8",
  });
  return file;
}

export const jsonToCsvFile = ({ data, fieldWrapper, addBom, filename }) => convertCsvStringToFile(convertJsonToCsvString(data, fieldWrapper), addBom, filename);

/**
 * Finds delimiter used in the CSV string
 *
 * @param {String} data CSV string
 * @returns {String} Delimiter
 */
export function findDelimiter(data) {
  var delimiters = ["	", ",", ";"];
  var usages = delimiters.map(function (delimiter) {
    return data.split(delimiter).length;
  });
  var maxUsage = usages.reduce(function (a, b) {
    return Math.max(a, b);
  });

  return delimiters[usages.indexOf(maxUsage)];
}

/**
 * Converts CSV file to JSON object
 *
 * @param {File} file CSV file
 * @returns {Array} JSON object
 */
export function convertFileToJsonObject(file) {
  return new Promise(function (resolve) {
    file.text().then(function (data) {
      var delimiter = findDelimiter(data);
      var array = [];
      var lines = data.split("\n");

      // Remove the first two lines
      lines.shift();
      lines.shift();

      var rows = lines.map(function (line) {
        var columns = line.split(delimiter);

        return columns.map(function (column) {
          if (column.startsWith('"') && column.endsWith('"')) {
            column = column.slice(1, column.length - 1);
            column = column.split('\\"').join('"');
          }

          return column;
        });
      });
      var fields = rows.shift();

      rows.forEach(function (row) {
        var object = {};

        fields.forEach(function (field, index) {
          object[field] = row[index] || "";
        });

        array.push(object);
      });

      resolve(array);
    });
  });
}

// Your File should be defined here, before using the command
//   var file = "";

//   convertFileToJsonObject(file).then(function (array) {
//     console.log(array);
//   });

/**
* Downloads file
*
* @param {File} file CSV File that will be downloaded
*/
export function downloadFile(file, filename) {
  var url = URL.createObjectURL(file);
  var link = document.createElement("a");
  link.href = url;
  link.download = (filename || file.name) + '.csv';

  link.click();
  link.remove();
}


export default { jsonToCsvFile, convertFileToJsonObject, downloadFile };