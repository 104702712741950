import actions from './actions'

const initialState = {
  settings: {
    id: '',
    bookTitle: '',
    price: 0,
    royalty: 0,
    vat: 0
  },
  searchId: '',
  result: null,
  loading: false
}

export default function acosReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.CLEAR_STATE:
      return { ...initialState, ...action.payload }

    case actions.PRODUCE_ACOS:
      return {
        ...state, settings: action.payload, loading: true
      }

    case actions.PRODUCE_ACOS_SUCCESS:
      return {
        ...state, result: action.data
      }

    default:
      return state
  }
}
