import { all, takeEvery, takeLatest, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import { history } from 'index'
import apiClient from 'services/axios'
import actions from './actions'

const getList = async (payload) => apiClient.get(`booksPayments`, { params: payload })

export function* GET_LIST({ payload }) {
  console.log(payload)
  try {
    const { data, status } = yield call(getList, payload)
    console.log(data)
    switch (status) {
      case 200:
        yield put({
          type: 'booksPayments/SET_STATE',
          payload: { ...data, booksPayments: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_LIST, GET_LIST),
  ])
}
