/* eslint-disable */
import { all, takeEvery, put, call, select, delay } from 'redux-saga/effects'
import { notification } from 'antd'
import { notification as ntf } from 'antd'
import apiClient from 'services/axios'
import actions from './actions'
import { produceAcos as optimizeAcosData } from './preduceResult'
import { CREATE as createSearch } from '../searches/sagas'

const produceAcos = async (payload) => apiClient.post(`produceAcos`, payload);

export function* PRODUCE_ACOS({ payload }) {

  try {
    const createdSearch = yield call(createSearch, { payload: { inputs: payload } });
    if (!createdSearch.searchId) {
      yield put({
        type: 'findAsins/SET_STATE',
        payload: { loading: false }
      });
      return false
    } else {
      const { data, status } = yield call(produceAcos, payload);
      yield put({
        type: 'acos/SET_STATE',
        payload: {
          loading: false,
          result: data,
          searchId: createdSearch.searchId,
        }
      });

      yield put({
        type: 'search/EDIT',
        payload: {
          result: data,
          id: createdSearch.searchId
        }
      });
    }
  }
  catch (error) {
    console.log('error', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.PRODUCE_ACOS, PRODUCE_ACOS),
  ])
}