import { all, takeEvery, put, call, select, delay } from 'redux-saga/effects'
import { notification as ntf } from 'antd'
import store from 'store'
import {history, store as reduxStore} from 'index'
import apiClient from 'services/axios'
import * as jwt from 'services/jwt'
import actions from './actions'

const getToken = state => state.user

const getList = async (payload) => apiClient.get(`users`, { params: payload })
const getListAll = async (payload) => apiClient.get(`usersAll`, { params: payload })
const getCurrent = async () => apiClient.get(`current`)
const getItem = async (id) => apiClient.get(`users/${id}`)
const createUser = async payload => apiClient.post(`users`, payload)
const editUser = async payload => apiClient.put(`users/${payload.id}`, payload)
const updateContact = async payload => apiClient.put(`update-contact/${payload.id}`, payload)
const updatePassword = async payload => apiClient.put(`updatePassword/${payload.id}`, payload)
const updateEmail = async payload => apiClient.put(`updateEmail/${payload.id}`, payload)
const fast1kAuthor = async payload => apiClient.put(`fast1kAuthor/${payload.id}`, payload)
const updateAvatar = async payload => apiClient.put(`fast1kAuthor/${payload.id}`, payload)
const deleteUser = async payload => apiClient.delete(`users/${payload.id}`)
const login = async payload => apiClient.post(`login`, payload)
const getUserSearches = async (payload) => apiClient.get(`user-searches`, { params: payload })
const createResetPassword = async payload => apiClient.post(`password/create`, payload)
const findTokenPasswordReset = async payload => apiClient.get(`password/find/${payload}`, payload)
const resetPassword = async payload => apiClient.post(`password/reset`, payload)
const createBankAccount = async payload => apiClient.post('bank-account', payload)
const addAvLessons = async payload => apiClient.post(`lessons/${payload.userId}`, payload)
const changeLanguage = async payload => apiClient.post(`languageUser/${payload.userId}`, payload)
const createKDP = async payload => apiClient.post(`kdp/${payload.userId}`, payload)
const createPlacement = async payload => apiClient.post(`createPlacement/${payload.kdp}`, payload)
const createTargeting = async payload => apiClient.post(`createTargeting/${payload.kdp}`, payload)
const createFeeding = async payload => apiClient.post(`createFeeding/${payload.kdp}`, payload)
const getAuthLogList = async id => apiClient.get(`users/${id}/login-count`)

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }) {
  const { data, status } = yield call(login, payload)
  switch (status) {
    case 200:
      store.set('accessToken', data.token)
      yield put({
        type: 'user/SET_STATE',
        payload: {
          ...data,
          authorized: true,
          loading: false,
        }
      })

      if (data.theme){
        const {theme} = data;
        reduxStore.dispatch({
          type: 'settings/SET_THEME',
          payload: {
            theme,
          },
        })
        reduxStore.dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'menuColor',
            value: theme,
          },
        })
      } else {
        reduxStore.dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'menuColor',
            value: 'dark',
          },
        })
        reduxStore.dispatch({
          type: 'settings/SET_THEME',
          payload: {
            theme: 'dark',
          },
        })
      }

      if (data.isSubscribed) {
        yield history.push('/dashboard/select-service')
      } else {
        yield history.push('/dashboard/subscriptions/create')
      }
      yield delay(3000)
      yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' })
      break
    case 422:
      ntf.error({ message: data })
      break
    default:
      console.log('Error: ', data)
  }
}

export function* REGISTER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].register, payload)
  if (response && !response.errors) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false
      },
    });

    ntf.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })

    yield put({
      type: 'user/LOGIN',
      payload: {
        email: response.email,
        password: payload.password
      },
    });

  } else {
    if (response.errors) {
      ntf.error({
        message: 'Unable to create the account',
        description: response.errors,
      })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }

}

export function* LOAD_CURRENT_ACCOUNT() {
  const { token } = yield select(getToken)
  if (!token) return false
  const { data, status } = yield call(getCurrent)
  switch (status) {
    case 200:
      if (token) {
        yield put({
          type: 'user/SET_STATE',
          payload: { ...data, isSubscribed: !!data.subscription?.price },
        })

        // yield delay(60 * 1000);
        // yield put({ type: 'user/LOAD_CURRENT_ACCOUNT' });

      }
      break
    default:
      yield put({ type: 'user/CLEAR_STATE' })
      yield put({ type: 'scan/CLEAR_STATE' })
      yield put({ type: 'findAsins/CLEAR_STATE' })
      localStorage.clear();
      console.log('Error: ', data.message)
  }
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  history.push('/');
  yield put({ type: 'user/CLEAR_STATE' })
  yield put({ type: 'scan/CLEAR_STATE' })
  yield put({ type: 'findAsins/CLEAR_STATE' })
  localStorage.clear();
}

export function* GET_ITEM({ id }) {
  const { userItem } = yield select(state => state.user)
  const userId = id || userItem.id;
  try {
    const { data, status } = yield call(getItem, userId)
    console.log(data)
    switch (status) {
      case 200:
        yield put({
          type: 'user/GET_ITEM_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_LIST({ payload }) {
  try {
    const { data, status } = yield call(getList, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'user/GET_LIST_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_LIST_ALL({ payload }) {
  try {
    const { data, status } = yield call(getListAll, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'user/GET_LIST_SUCCESS',
          payload: data,
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE({ payload }) {
  yield put({ type: 'user/SET_STATE', payload: { loading: true } });
  try {
    const { data, status } = yield call(createUser, payload)
    switch (status) {
      case 201:
        yield history.push(`/dashboard/users/${data.id}/show`)
        ntf.success({
          message: 'The user is posted Succesfully',
          description: 'We will contact you ass soon ass posible for any detail!',
        })
        break
      case 422:
        ntf.error({
          message: 'Unable to create the user',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
    yield put({ type: 'user/SET_STATE', payload: { loading: false } });
  } catch (error) {
    console.log(error)
  }

}

export function* EDIT({ payload }) {
  const { id: logedUserId } = yield select(state => state.user)
  try {
    const { data, status } = yield call(editUser, payload)
    switch (status) {
      case 200:
        if (logedUserId == payload.id) {
          yield put({ type: 'user/SET_STATE', payload: data })
        } else {
          yield put({ type: 'user/GET_ITEM_SUCCESS', payload: data })
        }
        ntf.success({ message: 'The details has been updated Succesfully' })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* UPDATE_PASSWORD({ payload }) {
  const { id: logedUserId } = yield select(state => state.user)
  try {
    const { data, status } = yield call(updatePassword, payload)
    switch (status) {
      case 200:
        if (logedUserId == payload.id) {
          yield put({ type: 'user/SET_STATE', payload: data })
        } else {
          yield put({ type: 'user/GET_ITEM_SUCCESS', payload: data })
        }
        ntf.success({ message: 'The Passoword has been updated Succesfully' })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* UPDATE_EMAIL({ payload }) {
  const { id: logedUserId } = yield select(state => state.user)
  try {
    const { data, status } = yield call(updateEmail, payload)
    switch (status) {
      case 200:
        if (logedUserId == payload.id) {
          yield put({ type: 'user/SET_STATE', payload: data })
        } else {
          yield put({ type: 'user/GET_ITEM_SUCCESS', payload: data })
        }
        ntf.success({ message: 'The Email has been updated Succesfully' })
        break
      default:
        ntf.error({ message: data.errors[0] })
        // console.log(data)
        // console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* FAST_1K_AUTHORS({ payload}) {
  const { id: logedUserId } = yield select(state => state.user)
  try {
    // const { data, status } = yield call(fast1kAuthor(payload), payload)
    const { data, status } = yield call(editUser, payload)
    switch (status) {
      case 200:
        if (logedUserId == payload.id) {
          yield put({ type: 'user/SET_STATE', payload: data })
        } else {
          yield put({ type: 'user/GET_ITEM_SUCCESS', payload: data })
        }
        ntf.success({ message: 'The details has been updated Succesfully' })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* UPDATE_AVATAR({ payload}) {
  try {
    const { data, status } = yield call(editUser, payload)
    switch (status) {
      case 200:
          yield put({ type: 'user/SET_STATE', payload: data })
        ntf.success({ message: 'The details has been updated Succesfully' })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* UPDATE_CONTACT({ payload}) {
  console.log(payload)
  try {

    const { data, status } = yield call(editUser, payload)
    switch (status) {
      case 200:
          yield put({ type: 'user/SET_STATE', payload: data })
        ntf.success({ message: 'The details has been updated Succesfully' })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* DELETE({ payload }) {
  try {
    const { data, status } = yield call(deleteUser, payload)
    switch (status) {
      case 200:
        ntf.success({
          message: 'The user is deleted Succesfully',
          description: '',
        })
        yield history.push(`/dashboard/users`)
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* GET_USERS_SEARCHES({ payload }) {
  try {
    const { data, status } = yield call(getUserSearches, payload)
    switch (status) {
      case 200:
        yield put({
          type: 'user/SET_STATE',
          payload: { userSearchesList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}


export function* CREATE_RESET_PASSWORD({ payload }) {
  try {
    const { status } = yield call(createResetPassword, payload)
    yield put({
      type: 'user/SET_STATE',
      payload: { resetPassStatus: status },
    })
  } catch (error) {
    console.log(error)
  }
}

export function* FIND_TOKEN_PASSWORD_RESET({ payload }) {
  try {
    const { status, data } = yield call(findTokenPasswordReset, payload)

    const isTokenValid = status == 200;
    if (!isTokenValid) {
      ntf.error({ message: data.message })
    }
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isTokenValid,
        resetingValues: data
      },
    })
  } catch (error) {
    console.log(error)
  }
}


export function* RESET_PASSWORD({ payload }) {
  try {
    const { status, data } = yield call(resetPassword, payload)
    if (status == 200) {
      yield history.push('/')
      ntf.success({ message: "You have successfully updated your password" })
    } else {
      ntf.error({ message: data.message })
    }
  } catch (error) {
    console.log(error)
  }
}

export function* ADD_BANK_ACCOUNT({ payload }) {
  try {
    const { data, status } = yield call(createBankAccount, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Bank details updated!" })
        yield put({ type: actions.LOAD_CURRENT_ACCOUNT })
        break
      case 422:
        ntf.error({
          message: 'Unable to add bank account',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* ADD_AVAILABLE_LESSONS({ payload }) {
  try {
    const { data, status } = yield call(addAvLessons, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Lessons visibility added" })
        break
      case 422:
        ntf.error({
          message: 'Unable to make lessons visible',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CHANGE_LANGUAGE({ payload }) {
  try {
    const { data, status } = yield call(changeLanguage, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Language is changed" })
        break
      case 422:
        ntf.error({
          message: 'Unable to make lessons visible',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE_KDP({ payload }) {
  try {
    const { data, status } = yield call(createKDP, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "KDP created" })
        yield put({ type: 'user/GET_ITEM', payload: payload.userId })
        break
      case 422:
        ntf.error({
          message: 'Error',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE_PLACEMENT({ payload }) {
  try {
    const { data, status } = yield call(createPlacement, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Placement enabled" })
        yield put({ type: 'user/GET_ITEM', payload: payload.userId })
        break
      case 422:
        ntf.error({
          message: 'Error',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE_TARGETING({ payload }) {
  try {
    const { data, status } = yield call(createTargeting, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Targeting enabled" })
        yield put({ type: 'user/GET_ITEM', payload: payload.userId })
        break
      case 422:
        ntf.error({
          message: 'Error',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* CREATE_FEEDING({ payload }) {
  try {
    const { data, status } = yield call(createFeeding, payload)
    switch (status) {
      case 200:
        ntf.success({ message: "Feeding enabled" })
        yield put({ type: 'user/GET_ITEM', payload: payload.userId })
        break
      case 422:
        ntf.error({
          message: 'Error',
          description: data.errors[0],
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export function* AUTH_LOG_LIST({ id }) {
  try {
    const { data, status } = yield call(getAuthLogList, id)
    switch (status) {
      case 200:
        yield put({
          type: 'user/SET_STATE',
          payload: { authLogList: data },
        })
        break
      default:
        console.log('Error: ', data.message)
    }
  } catch (error) {
    console.log(error)
  }

}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.GET_LIST, GET_LIST),
    takeEvery(actions.GET_LIST_ALL, GET_LIST_ALL),
    takeEvery(actions.GET_ITEM, GET_ITEM),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.EDIT, EDIT),
    takeEvery(actions.UPDATE_PASSWORD, UPDATE_PASSWORD),
    takeEvery(actions.UPDATE_EMAIL, UPDATE_EMAIL),
    takeEvery(actions.FAST_1K_AUTHORS, FAST_1K_AUTHORS),
    takeEvery(actions.UPDATE_AVATAR, UPDATE_AVATAR),
    takeEvery(actions.UPDATE_CONTACT, UPDATE_CONTACT),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.GET_USERS_SEARCHES, GET_USERS_SEARCHES),
    takeEvery(actions.CREATE_RESET_PASSWORD, CREATE_RESET_PASSWORD),
    takeEvery(actions.FIND_TOKEN_PASSWORD_RESET, FIND_TOKEN_PASSWORD_RESET),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.ADD_BANK_ACCOUNT, ADD_BANK_ACCOUNT),
    takeEvery(actions.ADD_AVAILABLE_LESSONS, ADD_AVAILABLE_LESSONS),
    takeEvery(actions.CREATE_KDP, CREATE_KDP),
    takeEvery(actions.CREATE_PLACEMENT, CREATE_PLACEMENT),
    takeEvery(actions.CREATE_TARGETING, CREATE_TARGETING),
    takeEvery(actions.CREATE_FEEDING, CREATE_FEEDING),
    takeEvery(actions.AUTH_LOG_LIST, AUTH_LOG_LIST),
    takeEvery(actions.CHANGE_LANGUAGE, CHANGE_LANGUAGE),
  ])
}
