import axios from 'axios'
import store from 'store'
import { history } from 'index'
import { isDev } from '../../constants'

const BASE_URL = isDev ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL;

const apiClient = axios.create({
  validateStatus(status) {
    switch (status) {
      case 500:
        // store.dispatch(error500());
        break;
      case 400:
        // Bad Request, maybe the data you are using are not correct!`
        break;
      case 401:
        // message: 'Your Session is Expired!'
        history.push('/')
        window.location.reload()
        break;
      case 403:
        // 'The request is forbiden for you!',
        break;
      // case 404:
      //  'Page Not found',
      //  break;
      default:
        return status >= 200 && status <= 503;
    }
    return false;
    // request finished
  },
  baseURL: BASE_URL,
});

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers = {
      ...request.headers,
      'X-Access-Token': `Bearer ${accessToken}`
    }
  }
  return request
});

apiClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    // Errors handling
    const { response } = error
    console.log(error);
    // const { data } = response
    // if (data) {
    //   notification.warning({
    //     message: data.toString(),
    //   })
    // }
  },
)

export default apiClient
