/* eslint-disable */

import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createHashHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Provider } from 'react-redux'
// import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import localforage from 'localforage'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Localization from './localization'
import Router from './router'

// add dotenv configs
require('dotenv').config()

// mocking api
// import 'services/axios/fakeApi'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
}

const rootPersistConfig = {
  key: 'fastbookads',
  storage: localforage,
  blacklist: ['router', 'menu', 'subscription', 'searches', 'affiliate'],
}

const persistedReducer = persistReducer(rootPersistConfig, reducers(history))

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <PersistGate loading={<span>loading</span>} persistor={persistor}>
        <Router history={history} />
      </PersistGate>
    </Localization>
  </Provider>,
  document.getElementById('root'),
)

export { store, history }
